$(document).ready(function(){
    
    $('.bd-panel-view').on('change', function(event) {
        let checkedValue = event.target.value;
        let productsList = $('.bd-cards-container');
        
        if (checkedValue === 'list') productsList.addClass('bd-list');
        if (checkedValue === 'grid') productsList.removeClass('bd-list');
        
        $(window).trigger('layout.change', [checkedValue]);
    });
    
});
