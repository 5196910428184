import debounce from "%functions%/wrappers";

export let hideHeadsAndBodies;

$(document).ready(function(){
    const tabs = document.querySelector('.bd-card-tabs');

    if (!tabs) return false;

    const heads = tabs.querySelectorAll('.bd-card-tabs__head');
    const bodies = tabs.querySelectorAll('.bd-card-tabs__body');
    const getStatusMobOrDesktop = () => window.innerWidth < 1200 ? 'mob' : 'desktop';
    let currentStatusMobOrDesktop = getStatusMobOrDesktop();

    hideHeadsAndBodies = () => {
        bodies.forEach(i => i.classList.remove('bd-show'));
        heads.forEach(i => i.classList.remove('bd-show'));
    };

    heads.forEach(head => {
        head.addEventListener('click', e => {
            const target = e.target;

            const number = target.dataset.tabHead;
            const body = tabs.querySelector(`[data-tab-body='${number}']`);

            if (window.innerWidth > 1200) {
                hideHeadsAndBodies();
                target.classList.add('bd-show');
                body.classList.add('bd-show');
            } else {
                target.classList.toggle('bd-show');
                body.classList.toggle('bd-show');
            }
            
            if (body.dataset.tabAction) {
                $(window).trigger('product.detail.action', [body.dataset.tabAction]);
            }
        });
    });

    window.addEventListener('resize', changeStatusMobOrDesktop);

    function changeStatusMobOrDesktop() {
        if (currentStatusMobOrDesktop !== getStatusMobOrDesktop()) {
            currentStatusMobOrDesktop = getStatusMobOrDesktop();
            hideHeadsAndBodies();
            bodies[0].classList.add('bd-show');
            heads[0].classList.add('bd-show');
        }
    };

    changeStatusMobOrDesktop = debounce(changeStatusMobOrDesktop, 50);

});
