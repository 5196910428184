var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bd-widget bd-widget-compare bd-widget--scrollbar",
      class: {
        "bd-show": _vm.bag.opened,
        "bd-widget--full": _vm.bag.count(),
        "bd-widget--empty": !_vm.bag.count(),
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "bd-widget__close",
          on: {
            click: function ($event) {
              return _vm.closeWidget()
            },
          },
        },
        [
          _c("icon", {
            attrs: { icon: "close", addClass: ["icon--inline-small"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bd-widget-empty__head" }, [
        _c("div", { staticClass: "bd-widget-empty__ico" }, [
          _c("svg", { staticClass: "bd-widget-empty__svg" }, [
            _c("use", {
              attrs: {
                "xlink:href": "/img/sprites/sprite.svg#header-shop-like",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "bd-widget-empty__title" }, [
          _vm._v("Избранное"),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "bd-widget-empty__desk" }, [
          _vm._v(
            "В списке " +
              _vm._s(_vm.padej(_vm.bag.count(), "товар", "товара", "товаров"))
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "bd-widget__full" }, [
        _c("div", { staticClass: "bd-widget-basket__head" }, [
          _c(
            "a",
            {
              staticClass:
                "bd-button bd-button--gray bd-widget-basket__button bd-button--wide",
              attrs: { href: _vm.url },
            },
            [_vm._v("Перейти к избранному")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bd-widget-basket__selected" },
          _vm._l(_vm.bag.products, function (product) {
            return _c("cart-widget-item", {
              key: "product" + product.id,
              attrs: { hideCart: true, product: product, bag: _vm.bag },
            })
          }),
          1
        ),
        _vm._v(" "),
        _vm.bag.sale_with
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.bag.sale_with) } })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bd-widget__empty bd-widget-empty" }, [
      _c("div", { staticClass: "bd-widget-empty__content" }, [
        _c("p", [_vm._v("В избранном нет товаров")]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "bd-button bd-button--accent",
            attrs: { href: "/catalog" },
          },
          [_vm._v("Каталог")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }