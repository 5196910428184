import Cart from './cart.js';

export default class Favorite extends Cart
{
    constructor(data)
    {
        super(data);
    }
    
    reloadFavorite(types)
    {
        
    }
}