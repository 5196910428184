<template>
    <li class="bd-search-input-result__item">
        <a class="bd-search-input-result__link" v-bind:href="product.url"></a>
        <img class="bd-search-input-result__img" v-bind:src="product.image">
        <div class="bd-search-input-result__content">
            <div class="bd-search-input-result__title" v-html="product.full_name"></div>
            <p class="bd-search-input-result__desc"></p>
        </div>
    </li>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'searchProduct',
        components: {

        },
        beforeMount: function () {

        },
        mounted: function () {

        },
        props: ['product'],
        computed: {
            ...mapState({
                    search: state => state.search
            })
        },
        methods: {

        },
        data: function () {
            return {
                timerId: null
            };
        }
    }
</script>