var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bd-fixed-menu bd-fixed-menu--city",
      class: { "bd-show": _vm.header.openedCityMenu },
    },
    [
      _c("div", { staticClass: "bd-fixed-menu__container" }, [
        _c(
          "div",
          {
            staticClass: "bd-fixed-menu__wrap bd-fixed-menu__wrap--first-level",
          },
          [
            _c(
              "svg",
              {
                staticClass: "bd-fixed-menu__close bd-only-desktop",
                on: {
                  click: function ($event) {
                    return _vm.closeWidget()
                  },
                },
              },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/img/sprites/sprite.svg#close--menu",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "bd-fixed-menu__head bd-only-mob" }, [
              _c(
                "div",
                {
                  staticClass: "bd-city bd-city--mob",
                  on: {
                    click: function ($event) {
                      return _vm.closeWidget()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "bd-city__geo",
                    attrs: { src: "/img/svg/city__geo.svg" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "bd-city__name",
                    domProps: { innerHTML: _vm._s(_vm.location.location.city) },
                  }),
                  _vm._v(" "),
                  _c(
                    "svg",
                    { staticClass: "bd-city__arrow bd-city__arrow--reverse" },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href": "/img/sprites/sprite.svg#city__arrow",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bd-fixed-menu__content" }, [
              _c("div", { staticClass: "bd-fixed-menu__title" }, [
                _vm._v("Выбрать город"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "bd-search-input bd-search-input--city bd-fixed-menu__input",
                },
                [
                  _c("div", { staticClass: "bd-search-input__top" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.text,
                          expression: "text",
                        },
                      ],
                      staticClass: "bd-search-input__input",
                      attrs: { type: "text", placeholder: "Поиск" },
                      domProps: { value: _vm.text },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.text = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "bd-search-input__ico-find",
                      attrs: { src: "/img/svg/search.svg" },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.sended && _vm.results.length
                ? _c(
                    "nav",
                    { staticClass: "bd-fixed-menu__nav" },
                    _vm._l(_vm.results, function (result) {
                      return _c(
                        "span",
                        {
                          key:
                            result.city +
                            result.country +
                            result.region +
                            result.area,
                          staticClass: "bd-fixed-menu__link",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.selectCity(result)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(result.city) +
                              " (" +
                              _vm._s(result.country) +
                              " " +
                              _vm._s(result.region) +
                              " " +
                              _vm._s(result.area) +
                              ")"
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sended && _vm.results.length == 0
                ? _c("nav", { staticClass: "bd-fixed-menu__nav" }, [
                    _c("span", { staticClass: "bd-fixed-menu__link" }, [
                      _vm._v("Не удалось найти населенный пункт"),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bd-fixed-menu__head bd-only-desktop" }, [
      _c(
        "a",
        { staticClass: "bd-logo bd-header__logo", attrs: { href: "/" } },
        [
          _c("img", {
            staticClass: "bd-logo__img",
            attrs: { src: "/img/svg/logo.svg" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }