$(document).ready(function(){
    
    
    $(document).on('click', '.button-product-cart', function(event){
        event.preventDefault();
        let button = this;
        let container = $(button).closest('.bd-product')[0] || $(button).closest('.bd-price-aside__container')[0];
        let count = $(container).find('.bd-count__value').val();
        
        $(window).trigger('cart.add', [$(button).data('id'), count]);
    });
    
    $(document).on('click', '.button-product-favorite', function(event){
        event.preventDefault();
        
        $(window).trigger('favorite.toggle', $(this).data('id'));
    });
    
    $(document).on('click', '.button-product-compare', function(event){
        event.preventDefault();
        
        $(window).trigger('compare.toggle', [$(this).data('id')]);
    });
    
    const updateProductButton = function(moduleName, ids) {
        $('.button-product-'+moduleName).removeClass('active').each(function(){
            let that = this;
            
            for (let i in ids) {
                if ($(that).data('id') && $(that).data('id') == ids[i]) {
                    $(that).addClass('active');
                }
            }
        });
    }
    
    $(window).on('catalog.cart.update', function(event, ids) {
        updateProductButton('cart', ids);
    });
    
    $(window).on('catalog.favorite.update', function(event, ids) {
        updateProductButton('favorite', ids);
    });
    
    $(window).on('catalog.compare.update', function(event, ids) {
        updateProductButton('compare', ids);
    });
});