export default class FormFieldRow {
    constructor(data) {
        var that = this;
        
        that.options = [];
        that.value = null;
        that.error = null;
        
        for (var i in data) {
            that[i] = data[i];
        }

        if (typeof data.error == undefined) {
            that.error = null;
        }

        if (!data.disable) {
            that.disable = false;
        }
        
        that.multiply = (that.multiply+'' == 'true') ? true : false;
        that.disabled = (that.disabled+'' == 'true') ? true : false;
        
        if (that.type == 'checkbox' && !Array.isArray(that.value)) {
            let oldVal = that.value;
            that.value = [];
            
            if (oldVal) {
                that.value.push(oldVal);
            }
        }
        
        if (that.type == 'file' || that.type == 'image') {
            that.value = [];
        }
    }
    
    isValid() {
        return this.error == null ? null : !this.error;
    }
    
    hasOptionsGroups()
    {
        for (var i in this.options) {
            if (this.options[i].group) {
                return true;
            }
        }
        
        return false;
    }
    
    getOptionsByGroups() {
        var groups = {};
        
        groups[''] = this.getOptionsByProp('group', null);
        
        for (let i in this.options) {
            if (this.options[i].group && !groups[this.options[i].group]) {
                groups[this.options[i].group] = this.getOptionsByProp('group', this.options[i].group);
            }
        }
        
        return groups;
    }
    
    getOptionsByProp(prop, value)
    {
        var res = [];

        for (var i in this.options) {
            if (this.options[i][prop] == value) {
                res.push(this.options[i]);
            }
        }

        return res;
    }
    
    getOptionsByType(type)
    {
        return this.getOptionsByProp('type', type);
    }
    
    getOptionBySlug(slug) {
        for (var i in this.options) {
            if (this.options[i].slug == slug) {
                return this.options[i];
            }
        }

        return null;
    }
    
    getOptionById(id)
    {
        for (var i in this.options) {
            if (this.options[i].id == id) {
                return this.options[i];
            }
        }

        return null;
    }
    
    changeValue(value)
    {
        this.value = value;
    }
    
    handleFiles(event)
    {
        this.value.splice(0, this.value.length);
        
        for( let i = 0; i < event.target.files.length; i++ ){
            this.value.push(event.target.files[i]);
        }
        
    }
}