$(document).ready(function () {
    const searchList = document.querySelector('.bd-search-list');

    if (!searchList) return false;

    const getStatusMobOrTablet = () => window.innerWidth < 768 ? 'mob' : 'tablet';
    let currentStatusMobOrTablet = getStatusMobOrTablet();

    const toggleSearchListMode = () => {
        if (currentStatusMobOrTablet === 'mob') {
            searchList.classList.remove('bd-list', 'bd-hide-on-mob')
        } else {
            searchList.classList.add('bd-list')
        }
    };

    window.addEventListener('resize', changeStatusMobOrTablet);

    function changeStatusMobOrTablet() {
        if (currentStatusMobOrTablet !== getStatusMobOrTablet()) {
            currentStatusMobOrTablet = getStatusMobOrTablet();
            toggleSearchListMode();
        }
    };

    toggleSearchListMode();
});
