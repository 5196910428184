var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bd-count", class: _vm.addClass }, [
    _c("div", { staticClass: "bd-count__wrap", class: _vm.addClassWrap }, [
      !_vm.disabled
        ? _c("span", { staticClass: "bd-count__minus" }, [_vm._v("-")])
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled ? _c("div", { staticClass: "bd-count__minus" }) : _vm._e(),
      _vm._v(" "),
      !_vm.disabled
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.propModel,
                expression: "propModel",
              },
            ],
            staticClass: "bd-count__value",
            attrs: { type: "text", "data-step": _vm.step, autocomplete: "off" },
            domProps: { value: _vm.propModel },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.propModel = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled
        ? _c("div", {
            staticClass: "bd-count__value",
            domProps: { innerHTML: _vm._s(_vm.propModel) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.measurement
        ? _c("div", { staticClass: "bd-count__measurement" }, [
            _vm._v(_vm._s(_vm.measurement)),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled
        ? _c("span", { staticClass: "bd-count__plus" }, [_vm._v("+")])
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled ? _c("div", { staticClass: "bd-count__plus" }) : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }