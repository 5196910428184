$(document).ready(function(){
    
    let infinityScroll = false;
    let nextPage = null;
    let loading = false;
    
    const loadMoreContent = () => {
        
        if (!loading) {
            
            loading = true;
            
            $.ajax({
                url: nextPage,
                method: 'get',
                data: {},
                complete(jqXHR) {
                    let data = jqXHR.responseJSON || {};
                    data.content = data.content || "<div></div>";
                    
                    if (data && data.content) {
                        try {
                            var html = $.parseHTML(data.content);
                            $(html).appendTo('.bd-more-container');
                        } catch (ex) {

                        }
                    }

                    if (data && data.nextPage) {
                        nextPage = data.nextPage;
                    } else {
                        nextPage = null;
                        infinityScroll = false;
                    }
                    
                    if (data && data.products) {
                        $(window).trigger('list-view', [$.parseJSON(data.products)]);
                    }
                    
                    loading = false;
                    
                    if (!infinityScroll) {
                        $('.bd-infinite-scroll').remove();
                    }
                }
            });
        }
    }
    
    $(document).on('click', '.bd-pagination__btn', function(){
        nextPage = $(this).data('url')
        infinityScroll = true;
        
        $('.bd-pagination').hide().after('<div class="bd-infinite-scroll"></div>');
        $('.bd-infinite-scroll').bnLoadBig(true);
        
        loadMoreContent();
    });
    
    $(window).on('pagination.reloaded', function(){
        infinityScroll = false;
    });
    
    $(window).on("scroll", function() {
        if (infinityScroll) {
            let contentHeight = $('.bd-infinite-scroll').offset().top;
            let yOffset       = window.pageYOffset;
            let window_height = window.innerHeight;
            let y             = yOffset + 2*window_height;
            
            if(y >= contentHeight) {
                loadMoreContent();
            }
        }
    });

});