import FormRow from '../models/form.js'

export default class Form {
    
    constructor(data)
    {
        this.localization = data.localization;
        this.loadForms(data.forms);
    }
    
    loadForms(data)
    {
        this.forms = this.buildForms(data);
    }
    
    buildForms(data)
    {
        let res = {};

        for (let i in data) {
            res[i] = this.buildForm(data[i]);
        }

        return res;
    }
    
    buildForm(data)
    {
        return new FormRow(data);
    }
    
    addForm(data, name)
    {
        this.forms[name] = this.buildForm(data);
    }
    
    form(name)
    {
        return this.forms[name];
    }
    
    loadFromUrl(url)
    {
        let that = this;
        
        return $.ajax({
            url: url,
            method: 'get',
            data: {}
        }).always(function(data) {
            if (data && data.form) {
                that.addForm(data.form, data.form.slug);
            }

            if (data && data.forms && data.forms.length) {
                for (let i in data.forms) {
                    that.addForm(data.forms[i]);
                }
            }
            
            return data;
        });
        
    }
    
    sendFormsWithFiles(forms, url, method = 'post')
    {
        let that = this;
        let dataToSend = new FormData();
        this.proccessFormsDataWithFiles(dataToSend, forms, 'forms');
        
        let deffered = $.ajax({
            url: url,
            method: method,
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: dataToSend
        });
        
        deffered.done(function(data, textStatus, jqXHR) {
            that.proccessForms(forms, data);
        });
        
        deffered.fail(function( jqXHR, textStatus, errorThrown) {
            that.proccessForms(forms, jqXHR.responseJSON || {});
        });
        
        return deffered;
    }
    
    proccessFormsDataWithFiles(dataToSend, forms, prefix = null)
    {
        let that = this;
        
        for (let i in forms) {
            let name = prefix ? prefix + '['+i+']' : i;
            
            if (Array.isArray(forms[i])) {
                that.proccessFormsDataWithFiles(dataToSend, forms[i], name);
            } else {
                forms[i].formData(dataToSend, name);
            }
        }
    }
    
    sendForms(forms, url, method = 'post')
    {
        let that = this;
        let dataToSend = this.proccessFormsData(forms);
        
        let deffered = $.ajax({
            url: url,
            method: method,
            enctype: 'multipart/form-data',
            data: {forms: dataToSend}
        });
        
        deffered.done(function(data, textStatus, jqXHR) {
            that.proccessForms(forms, data);
        });
        
        deffered.fail(function( jqXHR, textStatus, errorThrown) {
            that.proccessForms(forms, jqXHR.responseJSON || {});
        });
        
        return deffered;
    }
    
    proccessFormsData(forms)
    {
        let dataToSend = {};
        let that = this;

        for (let i in forms) {
            if (Array.isArray(forms[i])) {
                dataToSend[i] = that.proccessFormsData(forms[i]);
            } else {
                dataToSend[i] = forms[i].data();
            }
        }
        
        return dataToSend;
    }
    
    proccessForms(forms, data)
    {
        if (data && data.forms) {
            for(let j in data.forms) {
                
                if (Array.isArray(data.forms[j])) {
                    
                    for (let i in data.forms[j]) {
                        if (forms[j][i]) {
                            forms[j][i].proccessData({
                                form: data.forms[j][i]
                            });
                        }
                    }
                    
                } else {
                    forms[data.forms[j].slug].proccessData({
                        form: data.forms[j]
                    });
                }
            }
        }
    }
}