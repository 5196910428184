import "%components%/by/by-slider";
import "%components%/by/by-history";

$(document).ready(function() {
    $('.bd-by-content-media__video').on('click', function(){
        $(this).addClass('bd-by-content-media__video--active');
        
        var video = $(this).find('iframe, object, embed, video');
        
        if (video.length) {
            if (video[0].contentWindow) {
                video[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                video[0].contentWindow.postMessage({ "method": "play"}, '*');
            }
            if (video[0].play) {
                video[0].play();
            }
        }
    });
    
    $('.video-wraper').on('click', function(){
        $(this).addClass('active');
        
        var video = $(this).find('iframe, object, embed, video');
        
        if (video.length) {
            if (video[0].contentWindow) {
                video[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                video[0].contentWindow.postMessage({ "method": "play"}, '*');
            }
            if (video[0].play) {
                video[0].play();
            }
        }
    });
})