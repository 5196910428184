<template>
  <div class="bd-widget bd-widget-compare bd-widget--scrollbar" v-bind:class="{'bd-show': bag.opened, 'bd-widget--full': bag.count(), 'bd-widget--empty': !bag.count() }">

    <div class="bd-widget__close" @click="closeWidget()">
      <icon icon='close' :addClass='["icon--inline-small"]'></icon>
    </div>

    <div class="bd-widget-empty__head">
      <div class="bd-widget-empty__ico">
        <svg class="bd-widget-empty__svg">
        <use xlink:href="/img/sprites/sprite.svg#header-shop-like"></use>
        </svg>
      </div>
      <p class="bd-widget-empty__title">Избранное</p>
      <span class="bd-widget-empty__desk">В списке {{ padej(bag.count(), 'товар', 'товара', 'товаров') }}</span>

    </div>

    <div class="bd-widget__empty bd-widget-empty">
      <div class="bd-widget-empty__content">
        <p>В избранном нет товаров</p> <a class="bd-button bd-button--accent" href="/catalog">Каталог</a>
      </div>
    </div>

    <div class="bd-widget__full">

      <div class="bd-widget-basket__head">
        <a v-bind:href="url" class="bd-button bd-button--gray bd-widget-basket__button bd-button--wide">Перейти к избранному</a> 
      </div>

      <div class="bd-widget-basket__selected">
        <cart-widget-item :hideCart='true' :key="'product'+product.id" v-for='product in bag.products' :product='product' :bag='bag'></cart-widget-item>
      </div>

      <div v-if='bag.sale_with' v-html='bag.sale_with'></div>
    </div>

  </div>

</template>

<script>
  import cartWidgetItem from '%components%/bag/cartWidgetItem.vue';
  import { mapState } from 'vuex';
  import padej from '%functions%/padej.js';
  import icon from '%components%/icon.vue';

  export default {
    name: 'favoriteWidget',
    components: {
      cartWidgetItem, icon
    },
    beforeMount: function () {

    },
    mounted: function () {

    },
    props: ['url'],
    computed: {
      ...mapState({
          bag: state => state.favorite,
          currency: state => state.currency,
      })
    },
    methods: {
      padej() {
        return padej(...arguments);
      },
      closeWidget() {
        this.bag.opened = false;
      }
    },
    data: function () {
      return {

      };
    }
  }
</script>