$(document).ready(function () {
    
    $('.gallery').each(function(){
        
        let container = this;
        
        $(container).find('.gallery__small .gallery__wrap').slick({
            infinite: false,
            slidesToShow: 6,
            slidesToScroll: 6,
            focusOnChange: true,
//            focusOnSelect: true,
            autoplay: false,
            autoplaySpeed: 4000,
//            asNavFor:  $(container).find('.gallery__big .gallery__wrap'),
            vertical: true,
//            verticalSwiping: true,
            arrows: true,
            centerMode: false,
            prevArrow: $(container).find(".gallery__small .slick-prev"),
            nextArrow: $(container).find(".gallery__small .slick-next"),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: 3,
//                        slidesToScroll: 3,
                    }
                },
            ]
        });
                
        $(container).find('.gallery__small .slick-slide').each(function(index, item ) {
            $(item).on('click', function() {
                $(container).find(".gallery__big .gallery__wrap").slick('slickGoTo', index);
            })
        });
        
        $(container).find(".gallery__big .gallery__wrap").slick({
            infinite: false,
            slidesToShow: 1,
            autoplay: false,
            autoplaySpeed: 4000,
//            asNavFor: $(container).find('.gallery__small .gallery__wrap'),
            arrows: true,
            prevArrow: $(container).find(".gallery__big .slick-prev"),
            nextArrow: $(container).find(".gallery__big .slick-next"),
        }).on('setPosition', function(event, slick){
            $('.gallery__small .slick-slide')
                .removeClass('bd-slick-current')
                .eq(slick.slickCurrentSlide())
                .addClass('bd-slick-current')
            ;
        });

    });

});
