import Widget from './widget.js';

export default class User extends Widget
{
    constructor(data)
    {
        super();
        this.preloaded = false;
        this.url = data.url;
        
        data.user = data.user || {};
        
        this.fillUser(data.user);
    }
    
    fillUser(user)
    {
        this.id          = user.id          || null;
        this.last_name   = user.last_name   || null;
        this.name        = user.name        || null;
        this.second_name = user.second_name || null;
        this.email       = user.email       || null;
        
        if (this.roles && this.roles.length) {
            this.roles.splice(0, this.roles.length);
        } else {
            this.roles = [];
        }
        
        for (let i in user.roles) {
            this.roles.push(user.roles[i]);
        }
    }
    
    login(email, password, remember)
    {
        let that = this;
        
        let deffered = $.ajax({
            url: that.url.login,
            method: 'post',
            data: {
                'email': email,
                'password': password,
                'remember': remember
            }
        });
        
//        deffered.done(function(data) {
//            document.location.reload();
//        });
        
        return deffered;
    }
    
    recover(email)
    {
        let that = this;
        
        let deffered = $.ajax({
            url: that.url.recover,
            method: 'post',
            data: {
                'email': email
            }
        });
        
        return deffered;
    }
}
