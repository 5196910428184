var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    _vm._b(
      {
        class: _vm.addClass,
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      "svg",
      _vm.addAttributes,
      false
    ),
    [_c("use", { attrs: { "xlink:href": _vm.resolvePath + "#" + _vm.id } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }