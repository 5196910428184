$(document).ready(function () {
    $(document).on('click', 'header .bd-search-input__ico-enter', function() {
        $(this).closest('form').submit();
    });
    
    $(document).on('click', '.bd-header-sticky .icon__container', function(){
        
        if ($('body').width() >= 1200 && $(this).find('.icon--search').length) {
            $('html, body').animate({scrollTop: 0}, 500);
        }
    });
});
