export default class Layout 
{
    constructor(data)
    {
        this.url = data.url;
    }
    
    change(layout)
    {
        let that = this;
        
        $.ajax({
            url: this.url,
            method: 'put',
            processData: true,
            data: {
                "layout": layout
            },
            complete(jqXHR) {
                let data = jqXHR.responseJSON;
            }
        });
    }
}