import "%components%/personal/rate";

$(document).ready(function(){
    
    var orderButtonCallback = function(button, callback = null) {
        let url = button.data('url');
        let container = $(button.closest('.bd-history-status'));
        
        if (container) {
            container.bnLoadBig(true);
        }
        
        $.ajax({
            url: url,
            method: 'post',
            data: {}
        }).done(function(data, textStatus, jqXHR) {
            
            if (data && data.content && container) {
                try {
                    var html = $.parseHTML(data.content);
                    container.html(html);
                } catch (ex) {

                }
            }
        }).always(function() {
            if (container) {
                container.bnLoadBig(false);
            }
            
            if (callback) {
                callback();
            }
        });
    }
    
    $(document).on('keyup keydown focus blur', '.bd-button--order-clone-input', function(event) {
        let input = $(this);
        let button = $('.bd-button--order-clone');
        
        button.data('url', input.data('url').replace('%id%', input.val()));
    });
    
    $(document).on('click', '.bd-button--order-clone', function(event){
        event.preventDefault();
        
        let button = $(this);
        
        orderButtonCallback(button, function(){
            $(window).trigger('cart.fresh');
        });
    });
    
    $(document).on('click', '.bd-button--order-cancel', function(event){
        event.preventDefault();
        
        let button = $(this);
        
        orderButtonCallback(button);
    });
    
    $(document).on('change', '.bd-input--file', function(event){
        
    });
});