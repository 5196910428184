$(document).ready(function () {
    
    const lists = $('.bd-s-faq__list');
    if (!lists.length) return false;
    
    lists.each(function(){
        let list = this;
        let items = $(list).find('.bd-s-faq__item');
        
        $(items).each(function(){
            let item = this;
            let title = $(item).find('.bd-s-faq__title');
        
            $(title).on('click', function (){
                $(item).toggleClass('bd-show');
            })
            
        });
    })
        
});
