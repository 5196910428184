import Widget from './widget.js';
import Product from '../models/product.js';
import {getCookie, setCookie, deleteCookie} from '%components%/cookie'

export default class Search extends Widget
{
  constructor(data)
  {
    super();
    this.url = data.url;
    this.originalstring = data.query || '';
    this.querystring = data.query || '';
    this.category = null;
    this.props = [];
    this.products = [];
    this.sts   = [];
    this.taps  = [];
    this.tree  = [];
    this.count = 0;
    this.html  = '';
    
    this.proccessData(data);
    
    this.search_queries = this.searchQueriesCookie();
    this.clearQueries();
  }

  proccessData(data)
  {
    let that = this;
    
    if (data.query) {
      if (data.query != that.querystring) {
        that.originalstring = that.querystring;
        that.querystring = data.query;
      } else {
        that.originalstring = "";
      }

    }

    if (data && data.products) {
      that.loadProducts(data.products);
    }

    if (data && data.html) {
      that.html = data.html;
    } else {
      that.html = '';
    }

    if (data && data.sts) {
      that.sts = data.sts;
    } else {
      that.sts = [];
    }

    if (data && data.taps) {
      that.taps = data.taps;
    } else {
      that.taps = [];
    }

    if (data && data.tree) {
      that.tree = data.tree;
    } else {
      that.tree = [];
    }

    if (data.count) {
      that.count = data.count;
    } else {
      that.count = that.products.length;
    }
  }

  clear()
  {
    this.querystring = '';
    this.products.splice(0, this.products.length);
  }

  getById(id)
  {
    for (let i in this.products) {
      if (this.products[i].id == id) {
        return this.products[i];
      }
    }

    return null;
  }

  update()
  {
    let that = this;

    var deffered = that.search(that.querystring, that.category).then(function (data) {
      
      that.proccessData(data);

      return data;
    });

    deffered.then(() => that.saveQueryCookie(that.querystring));

    return deffered;
  }

  loadDefaultRecomendations()
  {
    let that = this;
    
    return that.search("", null, null).then(function (data) {
      that.proccessData(data);
      
      return data;
    })
  }

  search(querystring, category = null, url = null)
  {
    let that = this;

    var deffered = $.ajax({
      url: url || that.url,
      method: 'post',
      processData: true,
      data: {
        "query": querystring,
        "category": category,
      }
    });

    return deffered;
  }

  searchProducts(querystring, url = null)
  {
    let that = this;

    return that.search(querystring, null, url).then(function (data) {
      let res = {
        products: []
      };

      if (data && data.products) {
        res.products = that.buildProducts(data.products);
      }

      if (data.count) {
        res.count = data.count;
      } else {
        res.count = res.products.length;
      }

      return res;
    })
  }

  buildProduct(data)
  {
    return new Product(data);
  }

  buildProducts(data)
  {
    let res = [];

    for (let i in data) {
      res.push(this.buildProduct(data[i]));
    }

    return res;
  }

  loadProducts(data)
  {
    this.products.splice(0, this.products.length);

    let products = this.buildProducts(data);

    for (let i in products) {
      this.products.push(products[i]);
    }
  }

  saveQueryCookie(query)
  {
    if (query) {

      query = new String(query).trim();

      if (query.length) {

        this.search_queries.unshift(query);
        this.clearQueries();

        setCookie('search_queries', this.search_queries.join(';'), {SameSite: 'Lax', 'max-age': 31556926});
      }
    }
  }

  searchQueriesCookie()
  {
    var res = getCookie('search_queries');

    if (res) {
      res = res.split(';');
    } else {
      res = [];
    }

    return res;
  }

  removeQuery(index)
  {
    this.search_queries.splice(index, 1);

    setCookie('search_queries', this.search_queries.join(';'), {SameSite: 'Lax', 'max-age': 31556926});
  }

  clearQueries()
  {
    this.search_queries.forEach((x, i) => {
      if (this.search_queries.indexOf(x) !== i) {
        this.search_queries.splice(i, 1);
      }
    });

    if (this.search_queries.length > 10) {
      this.search_queries.splice(10);
    }
  }
}
