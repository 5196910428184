<template>
  <span class="icon" v-bind:class="addClass" v-bind="addAttributes" @click="$emit('click')">
    <slot>
      <iconsvg :id="icon"></iconsvg>
    </slot>
  </span>
</template>

<script>
import iconsvg from './icon-svg.vue';
export default {
  name: 'icon',
  components: {
      iconsvg
  },
  props: {
    'icon': [ String, Number ],
    'addClass': {
      type: [Array, String],
      default: function() { return []}
    },
    'addAttributes': {
      type: [Array, String],
      default: function() { return []}
    },
  },
  computed: {

  },
  data() {
    return {

    };
  }
}
</script>