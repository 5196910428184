var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-widget",
      class: { active: _vm.isActive, opened: _vm.isOpened },
    },
    [
      _c("div", { staticClass: "bd-wrap" }, [
        _c("div", { staticClass: "search-widget__container" }, [
          _c(
            "div",
            { staticClass: "bd-main-content search-widget__content" },
            [
              _c("div", { staticClass: "search-widget__title d-xl-none" }, [
                _vm._v("Поиск"),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass:
                    "search-widget__form d-xl-none bd-search-input bd-search-input--white bd-search-input--group",
                  attrs: { action: _vm.search.url },
                },
                [
                  _c("div", { staticClass: "bd-search-input__top" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.querystring,
                          expression: "search.querystring",
                        },
                      ],
                      staticClass: "bd-search-input__input",
                      attrs: {
                        type: "text",
                        placeholder: "Поиск",
                        name: "query",
                      },
                      domProps: { value: _vm.search.querystring },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.search,
                            "querystring",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    !_vm.search.querystring.length
                      ? _c("img", {
                          staticClass: "bd-search-input__ico-find",
                          attrs: { src: "/img/svg/search.svg" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.search.querystring.length
                      ? _c("img", {
                          staticClass:
                            "bd-search-input__ico-find cursor-pointer",
                          attrs: { src: "/img/svg/close--gray.svg" },
                          on: {
                            click: function ($event) {
                              return _vm.clearQuerystring()
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "bd-search-input__button-enter",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Найти")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.search.taps.length
                ? _c(
                    "div",
                    { staticClass: "search-widget__taps d-xl-none" },
                    _vm._l(_vm.searchTaps, function (q, index) {
                      return _c(
                        "span",
                        {
                          staticClass:
                            "bd-button bd-button--more-white me-2 mb-2",
                          on: {
                            click: function ($event) {
                              return _vm.resolveGoto(q)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(q.tap) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search-widget__result overflow-auto" },
                [
                  !_vm.firstSend || (!_vm.sended && !_vm.search.products.length)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bd-search-input-result__wrap bd-search-input-result__wrap--empty bd-show text-center",
                        },
                        [_c("p", [_vm._v("Начните вводить поисковую фразу")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.firstSend && _vm.search.products.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bd-search-input-result__header search-widget__result-header search-widget__result-header--big",
                        },
                        [
                          _vm._v(
                            "\n                      Популярные товары\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.firstSend && _vm.search.products.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bd-search-input-result__header search-widget__result-header",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Найдено: " +
                                _vm._s(
                                  _vm.padej(
                                    _vm.search.count,
                                    "товар",
                                    "товара",
                                    "товаров"
                                  )
                                )
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  _vm.search.url +
                                  "?query=" +
                                  _vm.search.querystring,
                              },
                            },
                            [_vm._v("Показать все")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sended && !_vm.search.products.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bd-search-input-result__header search-widget__result-header2",
                        },
                        [
                          _c("span", [
                            _vm._v("По вашему запросу ничего не найдено"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-widget__scroll d-none d-xl-flex" },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.search.products.length,
                            expression: "search.products.length",
                          },
                        ],
                        staticClass: "search-widget__scroll-container-products",
                        domProps: { innerHTML: _vm._s(_vm.search.html) },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "search-widget__advice" }, [
                _vm.searchQuerys.length
                  ? _c(
                      "div",
                      {
                        staticClass: "search-widget__header d-none d-xl-block",
                      },
                      [_vm._v("История")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchQuerys.length
                  ? _c(
                      "ul",
                      { staticClass: "search-widget__query-list" },
                      _vm._l(_vm.searchQuerys, function (q, index) {
                        return _c(
                          "li",
                          { staticClass: "search-widget__query-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "search-widget__query-q",
                                attrs: { href: _vm.resolveHref(q) },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.resolveGoto(q, "history")
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    icon: "icon-search-query",
                                    addClass: ["icon--inline"],
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(q) +
                                    "\n                            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("icon", {
                              attrs: {
                                icon: "icon-close",
                                addClass: [
                                  "icon--inline",
                                  "search-widget__query-close",
                                ],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.search.removeQuery(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.search.sts.length
                  ? _c(
                      "div",
                      {
                        staticClass: "search-widget__header d-none d-xl-block",
                      },
                      [_vm._v("Часто ищут")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.search.sts.length
                  ? _c(
                      "ul",
                      { staticClass: "search-widget__query-list" },
                      _vm._l(_vm.searchSts, function (q, index) {
                        return _c(
                          "li",
                          { staticClass: "search-widget__query-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "search-widget__query-q",
                                attrs: { href: _vm.resolveHref(q) },
                                on: {
                                  click: function ($event) {
                                    return _vm.resolveGoto(q, "st")
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    icon: "icon-search",
                                    addClass: ["icon--inline"],
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(q.st) +
                                    "\n                            "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.search.tree.length
                  ? _c(
                      "div",
                      {
                        staticClass: "search-widget__header d-none d-xl-block",
                      },
                      [_vm._v("Найти в категории")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.search.tree.length
                  ? _c(
                      "ul",
                      { staticClass: "search-widget__query-list" },
                      _vm._l(_vm.searchTree, function (q, index) {
                        return _c(
                          "li",
                          { staticClass: "search-widget__query-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "search-widget__query-q",
                                attrs: { href: _vm.resolveHref(q) },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.resolveGoto(q, "category")
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    icon: "icon-search-tree",
                                    addClass: ["icon--inline"],
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(q.name) +
                                    "\n                            "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("icon", {
                attrs: {
                  icon: "icon-close",
                  addClass: [
                    "icon--inline",
                    "search-widget__query-close-big",
                    "d-xl-none",
                  ],
                },
                on: {
                  click: function ($event) {
                    return _vm.closeWidget()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }