import FormFieldRow from './formField.js';

export default class FormRow {
    constructor(data) {
        let that = this;

        that.id = data.id;
        that.name = data.name;
        that.action = data.action;
        that.slug = data.slug;
        that.fields = [];
        
        for (let i in data.fields) {
            that.fields.push(new FormFieldRow(data.fields[i]))
        }
    }

    data()
    {
        let that = this;
        let data = {};

        for (let i in that.fields) {

            let field = that.fields[i];

            if (field.multiply) {

                let subdata = [];

                for (let j in field.value) {
                    subdata.push(field.value[j]);
                }

                data[field.slug] = subdata;
            } else {
                data[field.slug] = field.value;
            }

        }

        return data;
    }

    formData(data = null, prefix = null)
    {
        let that = this;
        data = data || new FormData();

        for (let i in that.fields) {
            
            let field = that.fields[i];
            let name = prefix ? prefix+'['+field.slug+']' : field.slug;

            if (field.multiply) {
                for (let j in field.value) {
                    data.append(name + '[]', field.value[j]);
                }
            } else {
                data.append(name, field.value);
            }

        }

        return data;
    }

    getField(fieldSlug)
    {
        for (var i in this.fields) {
            if (this.fields[i].slug == fieldSlug) {
                return this.fields[i];
            }
        }

        return null;
    }

    getValue(fieldSlug)
    {
        var field = this.getField(fieldSlug);

        return field ? field.value : null;
    }
    
    setValue(fieldSlug, value)
    {
        var field = this.getField(fieldSlug);
        
        if (field) {
            field.value = value;
        }
    }
    
    send(url = null, method = 'post')
    {
        let that = this;

        url = url || this.action;

        let deffered = $.ajax({
            url: url,
            method: method,
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: that.formData()
        });
        
        deffered.done(function(data, textStatus, jqXHR) {
            that.proccessData(data);
        });
        
        deffered.fail(function( jqXHR, textStatus, errorThrown) {
            that.proccessData(jqXHR.responseJSON || {});
        });
        
        return deffered;
    }
    
    clearErrors()
    {
        let that = this;
        
        for (let i in that.fields) {
            let field = that.fields[i];
            
            field.error = null;
        }
    }
    
    proccessData(data)
    {
        let that = this;
        
        if (!data || !data.form) {
            return;
        }
        
        for (let i in data.form.fields) {
            let field = that.getField(data.form.fields[i].slug);

            if (field) {
                if (field.type != 'file' && field.type != 'image') {
                    field.value = data.form.fields[i].value;
                }
                field.error = data.form.fields[i].error;
            }
        }
    }
}