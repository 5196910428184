var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bd-widget", class: { "bd-show": _vm.user.opened } },
    [
      _c(
        "div",
        {
          staticClass: "bd-widget__close",
          on: {
            click: function ($event) {
              return _vm.closeWidget()
            },
          },
        },
        [
          _c("icon", {
            attrs: { icon: "close", addClass: ["icon--inline-small"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.sayHello || !_vm.user.id
        ? _c(
            "div",
            {
              staticClass: "bd-widget-login bd-widget-login__hello",
              class: {
                "bd-hide": !_vm.sayHello,
                "bd-widget-login__hello--1":
                  _vm.resolveDayTimeExtraClass() == 1,
                "bd-widget-login__hello--2":
                  _vm.resolveDayTimeExtraClass() == 2,
                "bd-widget-login__hello--3":
                  _vm.resolveDayTimeExtraClass() == 3,
                "bd-widget-login__hello--4":
                  _vm.resolveDayTimeExtraClass() == 4,
              },
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.resolveDayTimeMessage()) + ","),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.user.name) + "!"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "bd-widget-login__hello--notice" }, [
                _vm._v("Обновляем страницу..."),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sayHello || !_vm.user.id
        ? _c(
            "div",
            {
              staticClass: "bd-widget-background",
              class: { "bd-hide": !_vm.sayHello },
            },
            [_c("img", { attrs: { alt: "", src: _vm.resolveDayTimeImage() } })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sayHello || !_vm.user.id
        ? _c(
            "div",
            {
              staticClass: "bd-widget-login",
              class: { "bd-hide": _vm.sayHello || _vm.recoverActive == true },
            },
            [
              _c("div", { staticClass: "bd-widget-login__title" }, [
                _vm._v("Вход"),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "bd-widget-login-form",
                  class: { "bd-error": _vm.hasErrors },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendLogin()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "bd-widget-login-form__wrap" }, [
                    _c("div", { staticClass: "bd-widget-login-form__item" }, [
                      _c(
                        "div",
                        { staticClass: "bd-widget-login-form__title" },
                        [_vm._v("Email")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "bd-widget-login-form__input",
                        attrs: {
                          type: "email",
                          name: "email",
                          required: "required",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bd-widget-login-form__item" }, [
                      _c(
                        "div",
                        { staticClass: "bd-widget-login-form__title" },
                        [_vm._v("Пароль")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        staticClass: "bd-widget-login-form__input",
                        attrs: {
                          type: "password",
                          name: "password",
                          required: "required",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "bd-widget-login-form__error" }, [
                    _vm._v(
                      "Неверно введен логин или пароль, попробуйте еще раз."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "bd-button bd-button--gray bd-widget-login-form__send",
                    },
                    [_vm._v("Войти")]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "bd-widget-login-form__check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.remember,
                          expression: "remember",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        checked: "checked",
                        value: "true",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.remember)
                          ? _vm._i(_vm.remember, "true") > -1
                          : _vm.remember,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.remember,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "true",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.remember = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.remember = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.remember = $$c
                          }
                        },
                      },
                    }),
                    _c("span", [_vm._v("Запомнить меня")]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "bd-widget-login-form__text" }, [
                _vm._v("\n      Если у вас нет аккаунта, пожалуйста\n      "),
                _c(
                  "a",
                  {
                    staticClass: "bd-widget-login-form__link",
                    attrs: { href: _vm.user.url.register },
                  },
                  [_vm._v("зарегистрируйтесь")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "bd-button bd-button--selection bd-widget-login-form__recovery",
                  on: {
                    click: function ($event) {
                      return _vm.openRecover()
                    },
                  },
                },
                [_vm._v("Восстановить пароль")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sayHello || !_vm.user.id
        ? _c(
            "div",
            {
              staticClass: "bd-widget-recovery",
              class: { "bd-show": !_vm.sayHello && _vm.recoverActive == true },
            },
            [
              _c("img", {
                staticClass: "bd-widget-recovery__back",
                attrs: { src: "/img/svg/fixed-menu__back.svg" },
                on: {
                  click: function ($event) {
                    return _vm.backWidget()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "bd-widget-recovery__title" }, [
                _vm._v("Восстановление пароля"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "bd-widget-recovery__text" }, [
                _vm._v(
                  "Если вы забыли пароль, введите E-Mail. Контрольная строка для смены пароля, а также ваши регистрационные данные, будут высланы вам по E-Mail."
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "bd-widget-login-form",
                  class: { "bd-error": _vm.recovered === false },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendRecover()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "bd-widget-login-form__head" }, [
                    _vm._v("Выслать ссылку для восстановления"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bd-widget-login-form__wrap" }, [
                    _c("div", { staticClass: "bd-widget-login-form__item" }, [
                      _c(
                        "div",
                        { staticClass: "bd-widget-login-form__title" },
                        [_vm._v("E-mail")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "bd-widget-login-form__input",
                        attrs: {
                          type: "email",
                          name: "email",
                          placeholder: "",
                          required: "required",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "bd-widget-login-form__error" }, [
                    _vm._v("Не удалось отправить ссылку, попробуйте еще раз."),
                  ]),
                  _vm._v(" "),
                  _vm.recovered
                    ? _c("p", {}, [
                        _vm._v("Ссылка отправлена на указанный email"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "bd-button bd-button--accent bd-widget-recovery__send",
                    },
                    [_vm._v("Выслать")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.sayHello && _vm.user.id ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }