import Widget from './widget.js';

export default class Modal extends Widget
{
  constructor()
  {
      super();

      this.registeredModals = [];
      this.openedModals     = [];
  }

  registered(name)
  {
    return this.registeredModals.includes(name);
  }

  register(name)
  {
    if (!this.registered(name)) {
      this.registeredModals.push(name);
    }
  }
  
  unregister(name)
  {
    if (this.registered(name)) {
      this.registered.splice(this.registered.indexOf(name), 1) ;
    }
    
    this.close(name);
  }
  
  isOpened(name)
  {
    return this.openedModals.includes(name);
  }

  open(name)
  {
    if (this.registered(name) && !this.isOpened(name)) {
      this.openedModals.push(name);
    }
    
    this.opened = this.hasOpened();
  }
  
  close(name)
  {
    if (this.isOpened(name)) {
      this.openedModals.splice(this.openedModals.indexOf(name), 1) ;
    }
    
    this.opened = this.hasOpened();
  }
  
  hasOpened()
  {
    return this.openedModals.length ? true : false;
  }
  
  closeOne()
  {
    if (this.openedModals.length)
    {
      this.close(this.openedModals[this.openedModals.length-1]);
    }
  }
}
