import debounce from "%functions%/wrappers";

$(document).ready(function () {

    if (document.querySelector('.bd-header__top')) {

        $(document).mouseup(function (e) {
            const container = $(".bd-city__tooltip.bd-show");
            if (container.has(e.target).length === 0 && !(e.target === container[0]) && !(e.target === $(".bd-city"))) {
                $(window).trigger('outside.city');
            }
        });
        
        $('.bd-header-nav__item--with-nested').each(function(){
            let target = this;
            
            $(target).on('click', function(event) {
                event.preventDefault();
                $(target).toggleClass('bd-show');
            })
            
            $(document).mouseup(function (e) {
                const container = $(".bd-header-nav__item--with-nested.bd-show");
                if (container.has(e.target).length === 0) {
                    $(target).removeClass('bd-show')
                }
            });
        });
        
        $('.bd-header-nav__item--with-nested .bd-header-nav__list-nested .bd-header-nav__link').on('click', function(event){
            event.stopPropagation();
        });
        
        const headerSticky = document.querySelector('.bd-header-sticky');

        const coordinatesViewportIsBottomHeader = () => {
            const coordinateWindowTop = window.pageYOffset;
            const headerHeight = document.querySelector('header').getBoundingClientRect().height;
            
            return headerHeight < coordinateWindowTop
        };

        const toggleHeaderSticky = debounce(function () {
            if (coordinatesViewportIsBottomHeader()) {
                document.querySelector('.bd-header-sticky').classList.add('bd-show');
            } else {
                document.querySelector('.bd-header-sticky').classList.remove('bd-show');
            }
        }, 50);

        window.addEventListener('scroll', toggleHeaderSticky);
    }
});