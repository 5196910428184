var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bd-widget bd-widget-basket bd-widget--scrollbar",
      class: {
        "bd-show": _vm.bag.opened,
        "bd-widget--full": _vm.bag.count(),
        "bd-widget--empty": !_vm.bag.count(),
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-none d-md-block bd-widget__close",
          on: {
            click: function ($event) {
              return _vm.closeWidget()
            },
          },
        },
        [
          _c("icon", {
            attrs: { icon: "close", addClass: ["icon--inline-small"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bd-widget-empty__head" }, [
        _c("div", { staticClass: "bd-widget-empty__ico" }, [
          _c("svg", { staticClass: "bd-widget-empty__svg" }, [
            _c("use", {
              attrs: {
                "xlink:href": "/img/sprites/sprite.svg#product__basket",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bag.count(),
                expression: "bag.count()",
              },
            ],
            staticClass: "bd-widget-empty__clear",
          },
          [
            _c(
              "a",
              {
                staticClass: "icon__container me-2",
                attrs: { href: "/cart/export.xlsx" },
              },
              [
                _c("icon", {
                  attrs: {
                    addClass: ["icon-xls", "icon--big", "icon--transparent"],
                    icon: "icon-xls",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "icon__container me-2",
                on: {
                  click: function ($event) {
                    _vm.showDelete = true
                  },
                },
              },
              [
                _c("icon", {
                  attrs: {
                    addClass: ["icon--big", "icon--transparent"],
                    icon: "trash-3",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "icon__container d-md-none",
                on: {
                  click: function ($event) {
                    return _vm.closeWidget()
                  },
                },
              },
              [
                _c("icon", {
                  attrs: { addClass: ["icon--transparent"], icon: "close" },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "bd-widget-empty__title" }, [_vm._v("Корзина")]),
        _vm._v(" "),
        _c("span", { staticClass: "bd-widget-empty__desk" }, [
          _vm._v(
            "В списке " +
              _vm._s(_vm.padej(_vm.bag.count(), "товар", "товара", "товаров"))
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bd-widget__scroller",
          class: { "bd-widget__scroller--slide": _vm.showDelete },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "bd-widget__full" }, [
            _c(
              "div",
              { staticClass: "bd-widget-basket__head" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "bd-button bd-button--accent-white bd-widget-basket__button",
                    attrs: { href: _vm.url },
                  },
                  [_vm._v("Оформить заказ")]
                ),
                _vm._v(" "),
                _c("price", {
                  attrs: {
                    price: _vm.bag.totalPrice(),
                    iso: "RUB",
                    addClass: "",
                    title: "Итого товаров на сумму:",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bd-widget-basket__selected" },
              _vm._l(_vm.bag.products, function (product) {
                return _c("cart-widget-item", {
                  key: "product" + product.id,
                  attrs: { product: product, bag: _vm.bag },
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.bag.sale_with
              ? _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.bag.sale_with) },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bd-widget__clear bd-widget-empty__content" },
            [
              _c("p", [_vm._v("Подтвердите удаление данных")]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass:
                      "bd-button bd-button--accent bd-button--small-padding",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clearBag.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Удалить")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "bd-button bd-button--gray bd-button--small-padding",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.showDelete = false
                      },
                    },
                  },
                  [_vm._v("Отменить")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bd-widget__empty bd-widget-empty" }, [
      _c("div", { staticClass: "bd-widget-empty__content" }, [
        _c("p", [
          _vm._v("В корзину пока"),
          _c("br"),
          _vm._v(" не добавлено товаров"),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "bd-button bd-button--accent",
            attrs: { href: "/catalog" },
          },
          [_vm._v("Каталог")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }