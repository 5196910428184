<template>
  <div class="bd-widget" v-bind:class="{'bd-show': user.opened}">

    <div class="bd-widget__close" @click="closeWidget()">
      <icon icon='close' :addClass='["icon--inline-small"]'></icon>
    </div>

    <div v-if='sayHello || !user.id' class="bd-widget-login bd-widget-login__hello" v-bind:class="{'bd-hide': !sayHello, 'bd-widget-login__hello--1': resolveDayTimeExtraClass() == 1, 'bd-widget-login__hello--2': resolveDayTimeExtraClass() == 2, 'bd-widget-login__hello--3': resolveDayTimeExtraClass() == 3, 'bd-widget-login__hello--4': resolveDayTimeExtraClass() == 4}">
      <p>{{ resolveDayTimeMessage() }},<br> {{ user.name }}!</p>

      <p class="bd-widget-login__hello--notice">Обновляем страницу...</p>
    </div>

    <div v-if='sayHello || !user.id' class="bd-widget-background" v-bind:class="{'bd-hide': !sayHello}">
      <img alt="" v-bind:src='resolveDayTimeImage()'>
    </div>

    <div v-if='sayHello || !user.id' class="bd-widget-login" v-bind:class="{'bd-hide': sayHello || recoverActive == true}">
      <div class="bd-widget-login__title">Вход</div>
      <form class="bd-widget-login-form" v-bind:class="{'bd-error': hasErrors}" v-on:submit.prevent='sendLogin()'>
        <div class="bd-widget-login-form__wrap">
          <div class="bd-widget-login-form__item">
            <div class="bd-widget-login-form__title">Email</div>
            <input type="email" name="email" v-model='email' class="bd-widget-login-form__input" required="required">
          </div>
          <div class="bd-widget-login-form__item">
            <div class="bd-widget-login-form__title">Пароль</div>
            <input type="password" name="password" v-model='password' class="bd-widget-login-form__input" required="required">
          </div>
        </div>
        <p class="bd-widget-login-form__error">Неверно введен логин или пароль, попробуйте еще раз.</p>
        <button class="bd-button bd-button--gray bd-widget-login-form__send">Войти</button>
        <label class="bd-widget-login-form__check">
          <input type="checkbox" checked="checked" v-model='remember' value="true"><span>Запомнить меня</span>
        </label>
      </form>
      <p class="bd-widget-login-form__text">
        Если у вас нет аккаунта, пожалуйста
        <a class="bd-widget-login-form__link" v-bind:href='user.url.register'>зарегистрируйтесь</a>
      </p>
      <button class="bd-button bd-button--selection bd-widget-login-form__recovery" v-on:click="openRecover()">Восстановить пароль</button>
    </div>

    <div v-if='sayHello || !user.id' class="bd-widget-recovery" v-bind:class="{'bd-show': !sayHello && recoverActive == true}">
      <img @click="backWidget()" src="/img/svg/fixed-menu__back.svg" class="bd-widget-recovery__back">
      <div class="bd-widget-recovery__title">Восстановление пароля</div>
      <p class="bd-widget-recovery__text">Если вы забыли пароль, введите E-Mail. Контрольная строка для смены пароля, а также ваши регистрационные данные, будут высланы вам по E-Mail.</p>
      <form class="bd-widget-login-form" v-bind:class="{'bd-error': recovered === false}" v-on:submit.prevent='sendRecover()'>
        <div class="bd-widget-login-form__head">Выслать ссылку для восстановления</div>
        <div class="bd-widget-login-form__wrap">
          <div class="bd-widget-login-form__item">
            <div class="bd-widget-login-form__title">E-mail</div>
            <input type="email" name="email" v-model='email' placeholder="" required="required" class="bd-widget-login-form__input">
          </div>
        </div>
        <p class="bd-widget-login-form__error">Не удалось отправить ссылку, попробуйте еще раз.</p>
        <p class="" v-if='recovered'>Ссылка отправлена на указанный email</p>
        <button class="bd-button bd-button--accent bd-widget-recovery__send">Выслать</button>
      </form>
    </div>

    <slot v-if='!sayHello && user.id'></slot>
  </div>
</template>

<script>
  import { mapState }  from 'vuex';
  import icon from '%components%/icon.vue';

  export default {
    name: 'loginWidget',
    components: {
      icon
    },
    beforeMount: function () {
      this.user.preloaded = true;
    },
    mounted: function () {

    },
    props: [

    ],
    computed: {
      ...mapState({
          cart: store => store.cart,
          location: store => store.location,
          form: store => store.form,
          currency: store => store.currency,
          user: store => store.user
      })
    },
    methods: {
      closeWidget() {
        this.user.opened = false;
        this.recoverActive = false;
      },
      backWidget() {
        if (this.recoverActive) {
          this.recoverActive = false;
        } else {
          this.closeWidget();
        }
      },
      openRecover() {
        this.recoverActive = true;
      },
      sendLogin() {
        let that = this;
        let deffered = this.user.login(this.email, this.password, this.remember);

        deffered.done(function (data) {
          that.hasErrors = false;
          that.sayHello = true;
          that.user.fillUser(data.user);

          setTimeout(function () {
            document.location.reload();
          }, 2000);
        });

        deffered.fail(function () {
          that.hasErrors = true;
        });
      },
      resolveDayTimeMessage() {
        let currentTime = new Date();
        let hours = currentTime.getHours();

        if (hours < 6 || hours > 21) {
          return 'Доброй ночи';
        } else if (hours >= 6 && hours < 10) {
          return 'Доброе утро';
        } else if (hours >= 10 && hours < 19) {
          return 'Добрый день';
        } else {
          return 'Добрый вечер';
        }
      },
      resolveDayTimeImage() {
        let currentTime = new Date();
        let hours = currentTime.getHours();

        if (hours < 6 || hours > 21) {
          return '/img/personal-hello-0.jpg';
        } else if (hours >= 6 && hours < 10) {
          return '/img/personal-hello-1.jpg';
        } else if (hours >= 10 && hours < 19) {
          return '/img/personal-hello-2.jpg';
        } else {
          return '/img/personal-hello-3.jpg';
        }
      },
      resolveDayTimeExtraClass() {
        let currentTime = new Date();
        let hours = currentTime.getHours();

        if (hours < 6 || hours > 21) {
          return 1;
        } else if (hours >= 6 && hours < 10) {
          return 2;
        } else if (hours >= 10 && hours < 19) {
          return 3;
        } else {
          return 4;
        }
      },
      sendRecover() {
        let that = this;
        let deffered = this.user.recover(this.email);

        deffered.done(function () {
          that.recovered = true;
        });
        deffered.fail(function () {
          that.recovered = false;
        });
      }
    },
    data: function () {
      return {
        recoverActive: false,
        hasErrors: false,
        sayHello: false,
        recovered: null,
        email: '',
        password: '',
        remember: 'true'
      };
    }
  }
</script>