<template>
    <div class="bd-fixed-menu bd-fixed-menu--city" v-bind:class="{'bd-show': header.openedCityMenu}">
        <div class="bd-fixed-menu__container">
            <div class="bd-fixed-menu__wrap bd-fixed-menu__wrap--first-level">
                <svg class="bd-fixed-menu__close bd-only-desktop" @click='closeWidget()'>
                    <use xlink:href="/img/sprites/sprite.svg#close--menu"></use>
                </svg>
                <div class="bd-fixed-menu__head bd-only-desktop">
                    <a href="/" class="bd-logo bd-header__logo"><img src="/img/svg/logo.svg" class="bd-logo__img"></a>
                </div>
                <div class="bd-fixed-menu__head bd-only-mob">
                    <div class="bd-city bd-city--mob" @click="closeWidget()">
                        <img src="/img/svg/city__geo.svg" class="bd-city__geo">
                        <p class="bd-city__name" v-html="location.location.city"></p>
                        <svg class="bd-city__arrow bd-city__arrow--reverse"><use xlink:href="/img/sprites/sprite.svg#city__arrow"></use></svg>
                    </div>
                </div>
                <div class="bd-fixed-menu__content">
                    <div class="bd-fixed-menu__title">Выбрать город</div>
                    <div class="bd-search-input bd-search-input--city bd-fixed-menu__input">
                        <div class="bd-search-input__top">
                            <input v-model="text" type="text" placeholder="Поиск" class="bd-search-input__input">
                            <img src="/img/svg/search.svg" class="bd-search-input__ico-find">
                        </div>
                    </div>
                    <nav v-if="sended && results.length" class="bd-fixed-menu__nav">
                        <span class="bd-fixed-menu__link" :key='result.city + result.country + result.region + result.area' v-for="result in results" @click.prevent="selectCity(result)">{{ result.city }} ({{ result.country }} {{ result.region }} {{ result.area }})</span>
                    </nav>
                    <nav v-if="sended && results.length == 0" class="bd-fixed-menu__nav">
                        <span class="bd-fixed-menu__link">Не удалось найти населенный пункт</span>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'headerLocation',
    components: {
        
    },
    props: [],
    methods: {
        closeWidget() {
            this.header.close('CityMenu');
        },
        selectCity: function(location)
        {
            let oldCountry = this.location.location.country;
            let newCountry = location.country;
            
            this.location.select(location).then(function(){
                if (oldCountry != newCountry) {
                    window.location.reload();
                }
            });
            
            this.text = "";
            this.results = [];
            this.sended = false;
            this.closeWidget();
        }
    },
    computed: {
        ...mapState({
            location: state => state.location,
            header: state => state.header
        })
    },
    watch: {
        loading() {
            $(this.$el).bnLoadBig(this.loading);
        },
        "text": function(newVal, oldVal) {
            let that = this;
            
            clearTimeout(that.timerId);
            that.results = [];
            that.sended = false;
            
            if (newVal.length >= 2) {
                
                that.timerId = setTimeout(function() {
                    
                    that.loading = true;
                    
                    that.location.search(newVal).then(function(citys) {
                        that.results = citys;
                        that.sended = true;
                        that.loading = false;
                    });
                    
                }, 1000);
            }
        }
    },
    data: function() {
        return {
            "sended": false,
            "loading": false,
            "text": "",
            "results": [],
            "timerId": null
        };
    }
}
</script>    