export default class LocationRaw {
    constructor(data) {
        let that = this;
        that.address = data.address;
        that.iso = data.iso;
        that.country = data.country;
        that.region = data.region;
        that.area = data.area;
        that.city = data.city;
    }
    
    shortData()
    {
        return {
            country:  this.country.name || this.country,
            region: this.region.short_name || this.region,
            area: this.area ? this.area.short_name || this.area : null,
            city: this.city.short_name || this.city,
        }
    }
}