export default function (container, more) {

    const _container = $(container)[0];
    const _more = $(more)[0];
    const _moreSpan = $(more).find('span')[0];

    if (_more) {
        $(_more).on('click', e => {
            e.preventDefault();

            if (_container.classList.contains('bd-hide')) {
                _container.classList.remove('bd-hide');
                try {
                    _moreSpan.innerText = 'Свернуть';
                } catch (e) {
                    _more.innerText = 'Свернуть';
                }
            } else {
                _container.classList.add('bd-hide');
                try {
                    _moreSpan.innerText = 'Развернуть';
                } catch (e) {
                    _more.innerText = 'Развернуть';
                }
            }

        });
    }
};
