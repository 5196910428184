$(document).ready(function () {
    const form = document.querySelector('.bd-price-aside-promo');
    if (!form) return false;

    const input = form.querySelector('input');
    const success = form.querySelector('.bd-price-aside-promo__success');

    form.addEventListener('submit', e => {
        e.preventDefault();

        if (input.value) {
            success.classList.add('bd-show');
        } else {
            success.classList.remove('bd-show');
        }
    })
});
