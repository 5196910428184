$(document).ready(function () {
    const wraps = document.querySelectorAll('.bd-news-template-spoiler');
    if (wraps.length === 0) return false;

    wraps.forEach(wrap => {
        const btn = wrap.querySelector('.bd-news-template-spoiler__more');

        btn.addEventListener('click', () => {
            wrap.classList.add('bd-active')
        });
    })
});
