<template>
    <div class="bd-search-input-result">
        <div class="bd-search-input-result__container">
            <div class="bd-search-input-result__wrap bd-search-input-result__wrap--empty bd-show" v-if='!firstSend || (!sended && !search.products.length)'>
                <p>Начните вводить поисковую фразу</p>
            </div>
            <div class="bd-search-input-result__wrap bd-search-input-result__wrap--result bd-show" v-if='sended && !search.products.length'>
                <div class="bd-search-input-result__header">
                    <span>Товаров не найдено.</span><a v-bind:href="search.url+'?query='+search.querystring">Показать все результаты</a>
                </div>
            </div>
            <div class="bd-search-input-result__wrap bd-search-input-result__wrap--result bd-show" v-if='search.products.length'>
                <div class="bd-search-input-result__header">
                    <span>Найдено: {{ padej(search.count, 'товар', 'товара', 'товаров') }}</span><a v-bind:href="search.url+'?query='+search.querystring">Показать все</a>
                </div>
                <ul class="bd-search-input-result__list">
                    <search-product :key="'product'+product.id" v-for='product in search.products' :product='product'></search-product>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import searchProduct from './searchProduct.vue';
import padej from '../../functions/padej.js';

export default {
    name: 'searchWidget',
    components: {
        searchProduct
    },
    beforeMount: function () {

    },
    mounted: function () {

    },
    props: [],
    computed: {
        ...mapState({
            search: state => state.search
        })
    },
    watch: {
        'search.querystring': function() {
            this.sended = false;
            this.search.opened = true;
            if (this.search.querystring.length > 2) {
                this.update();
            }
        },
        loading() {
            $(this.$el).bnLoadBig(this.loading);
        }
    },
    methods: {
        padej() {
            return padej(...arguments);
        },
        update()
        {
            let that = this;
            clearTimeout(that.timerId);

            that.timerId = setTimeout(function() {
                that.firstSend = true;
                that.loading = true;
                that.search.update().then(function(){
                    that.sended = true;
                    that.loading = false;
                });
            }, 500);
        }
    },
    data: function () {
        return {
            timerId: null,
            sended: false,
            loading: false,
            firstSend: false
        };
    }
}
</script>