import tabs from "%components%/tabs";

$(document).ready(function() {
    tabs('.bd-personal-rate');
    
    const filtersCat = document.querySelector(".bd-filters-portable-cat");
    const filtersCatTitle = document.querySelector(".bd-filters-portable-cat__title");
    const filtersCatHead = document.querySelector(".bd-filters-portable-cat__head");
    const filtersCatHeadP = document.querySelector(".bd-filters-portable-cat__title p");
    const bodyfilters = document.querySelector("body");


    if(filtersCatHead != undefined ){
    bodyfilters.addEventListener("click", e => {
        filtersCatHead.classList.remove("bd-filters-portable-cat__head--active");
        filtersCat.classList.remove("bd-show");
    })}

    if (filtersCatTitle != undefined) {
        filtersCatTitle.addEventListener("click", e => {
            let check = document.querySelector(".bd-filters-portable-cat__head--active");
            if (check == undefined) {
                setTimeout(() => {
                    e.preventDefault();
                    filtersCat.classList.add("bd-show");
                    filtersCatHead.classList.add("bd-filters-portable-cat__head--active");
                }, 10);
            }
        })
    }

    document.querySelectorAll('.bd-filters-portable-cat__link').forEach(item => {
        item.addEventListener('click', event => {

            document.querySelectorAll('.bd-personal-rate__body').forEach(item => {
                item.classList.remove("bd-current");
            })
            const filtersCatHeadClick = item.getAttribute('data-tab-head')
            
            if (filtersCatHeadClick) {
                document.querySelector("[data-tab-body='" + filtersCatHeadClick + "']").classList.add("bd-current");
            }
            filtersCatHeadP.textContent = item.textContent
        })
    })
});



    