import spoiler from '%components%/show-hide';

$(document).ready(function(){

    $(".bd-category-info__slider-big").slick({
        infinite: true,
        slidesToShow: 1,
        // autoplay: true,
        autoplaySpeed: 4000,
        asNavFor: '.bd-category-info__slider-small',
        adaptiveHeight: true,
//        prevArrow: $(".bd-category-info__slider-big .slick-prev"),
//        nextArrow: $(".bd-category-info__slider-big .slick-next")
    });

    $(".bd-category-info__slider-small").slick({
        infinite: true,
        slidesToShow: 5,
        focusOnSelect: true,
        // autoplay: true,
        autoplaySpeed: 4000,
        asNavFor: '.bd-category-info__slider-big',
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3
                }
            }
        ],
//        prevArrow: $(".bd-category-info__slider-small .slick-prev"),
//        nextArrow: $(".bd-category-info__slider-small .slick-next")
    });

    (function () {
        if (window.innerWidth < 1200) {
            const container = document.querySelector('.bd-category-info__spoiler');
            if (!container) return false;

            container.classList.add('bd-hide');
            spoiler('.bd-category-info__spoiler', '.bd-category-info__more');
        }

    })();

});