$(document).ready(function () {
    const wrap = document.querySelector('.bd-news-template-faq');
    if (!wrap) return false;

    const titles = wrap.querySelectorAll('.bd-news-template-faq__title');

    titles.forEach(title => {
        const item = title.closest('.bd-news-template-faq__item');

        title.addEventListener('click', () => {
            item.classList.toggle('bd-active');
        });
    })

});
