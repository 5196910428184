<template>
    <div class="bd-widget bd-widget-basket bd-widget--scrollbar" v-bind:class="{'bd-show': bag.opened, 'bd-widget--full': bag.count(), 'bd-widget--empty': !bag.count() }">
        
        <div class="d-none d-md-block bd-widget__close" @click="closeWidget()">
          <icon icon='close' :addClass='["icon--inline-small"]'></icon>
        </div>
        
        <div class="bd-widget-empty__head">
            <div class="bd-widget-empty__ico">
              <svg class="bd-widget-empty__svg">
                <use xlink:href="/img/sprites/sprite.svg#product__basket"></use>
              </svg>
            </div>
            
            <div class="bd-widget-empty__clear" v-show='bag.count()'>
              
              <a class="icon__container me-2" href="/cart/export.xlsx">
                <icon :addClass='["icon-xls", "icon--big", "icon--transparent"]' icon='icon-xls'></icon>
              </a>
              
              <div class="icon__container me-2" @click="showDelete = true">
                <icon :addClass='["icon--big", "icon--transparent"]' icon='trash-3'></icon>
              </div>
              
              <div class="icon__container d-md-none" @click="closeWidget()">
                <icon :addClass='["icon--transparent"]' icon='close'></icon>
              </div>
              
            </div>
            
            <p class="bd-widget-empty__title">Корзина</p>
            <span class="bd-widget-empty__desk">В списке {{ padej(bag.count(), 'товар', 'товара', 'товаров') }}</span>
        </div>
        
        <div class="bd-widget__scroller" v-bind:class="{'bd-widget__scroller--slide': showDelete}">
        
            <div class="bd-widget__empty bd-widget-empty">
                <div class="bd-widget-empty__content">
                    <p>В корзину пока<br> не добавлено товаров</p> <a class="bd-button bd-button--accent" href="/catalog">Каталог</a>
                </div>
            </div>

            <div class="bd-widget__full">

                <div class="bd-widget-basket__head">
                    <a v-bind:href="url" class="bd-button bd-button--accent-white bd-widget-basket__button">Оформить заказ</a> 
                    <price :price="bag.totalPrice()" iso='RUB' addClass="" title="Итого товаров на сумму:"></price>
                </div>

                <div class="bd-widget-basket__selected">
                    <cart-widget-item :key="'product'+product.id" v-for='product in bag.products' :product='product' :bag='bag'></cart-widget-item>
                </div>

                <div v-if='bag.sale_with' v-html='bag.sale_with'></div>
            </div>
            
            <div class="bd-widget__clear bd-widget-empty__content">
                <p>Подтвердите удаление данных</p>
                <div>
                    <button type="button" class="bd-button bd-button--accent bd-button--small-padding" @click.prevent="clearBag">Удалить</button>
                    <button type="button" class="bd-button bd-button--gray bd-button--small-padding" @click.prevent="showDelete = false">Отменить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import cartWidgetItem from '%components%/bag/cartWidgetItem.vue';
    import { mapState } from 'vuex';
    import price from '%components%/price/price.vue';
    import padej from '%functions%/padej.js';
    import icon from '%components%/icon.vue';
    
    export default {
        name: 'cartWidget',
        components: {
            cartWidgetItem, price, icon
        },
        beforeMount: function () {

        },
        mounted: function () {

        },
        props: ['url'],
        computed: {

        },
        watch: {
            "bag.loading": function() {
                $(this.$el).bnLoadBig(this.bag.loading);
            },
            'bag.opened': function(newVal) {
                if (!newVal) {
                    this.showDelete = false;
                }
            }
        },
        methods: {
            padej() {
                return padej(...arguments);
            },
            closeWidget() {
                this.bag.opened = false;
            },
            clearBag() {
                this.bag.clear().then(() => this.showDelete = false);
            }
        },
        computed: {
            ...mapState({
                    bag: state => state.cart,
                    currency: state => state.currency,
            })
        },
        data() {
            return {
                showDelete: false
            }
        }
    }
</script>