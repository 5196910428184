import Widget from './widget.js';

export default class Feedback extends Widget
{
    constructor(data)
    {
        super();
        this.preloaded = false;
        this.sended = false;
        this.themes = {};
        this.theme_fields = {};
        this.theme_descriptions = {};
        
        this.url = data.url;
    }
    
    setFormManager(manager)
    {
        this.form = manager;
    }
    
    getForm()
    {
        return this.form.form('feedback');
    }
    
    loadForm()
    {
        let that = this;
        
        return this.form.loadFromUrl(this.url, 'feedback').then(function(data) {
            that.preloaded = true;
            that.themes = data.themes;
            that.theme_fields = data.theme_fields;
            that.theme_descriptions = data.theme_descriptions;
        });
    }
    
    sendForm()
    {
        return this.getForm().send(this.url);
    }
    
    getTheme()
    {
        this.getForm() ? this.getForm().getValue('theme') : null;
    }
    
    clearTheme() {
        this.getForm().getField('theme').value = null;
    }
}