$(document).ready(function(){
    
    const closeTooltip = function() {
        $(window).trigger('filter.tooltip', [false]);
    }
    
    $(document).on('click', '.bd-filters-cat-item__head', function(event) {
        event.stopPropagation();
        $(this).closest('.bd-filters-cat-item').toggleClass('bd-show');
        closeTooltip();
    });
    
    $(document).on('click', '.bd-filters-cat-tooltip__close', function(event) {
        event.preventDefault();
        closeTooltip();
    });
    
    $(window).on('keyup', e => {
        if (e.code === 'Escape') closeTooltip()
    });
    
    document.querySelectorAll('.bd-filters-cat__wrap').forEach(function(wrap) {
        let items = $(wrap).find('.bd-filters-cat-item');
        let tooltip = wrap.querySelector('.bd-filters-cat-tooltip');
        let form = wrap.querySelector('.bd-filters-cat form');

        if (tooltip && form && items.length) {

            let from = form.querySelector('.bd-filters-cat-item-price__input-range--from');
            let to = form.querySelector('.bd-filters-cat-item-price__input-range--to');
            let currensys = form.querySelectorAll('.bd-filters-cat-item-price__input-range--currency');
            let inputs = [from, to];
            
            $('.bd-filters-cat-item:not(.bd-filters-cat-item-price) .bd-filters-cat-item__input-content').on('change', function() {
                changeCoordinatesTooltip(this);
                if (this.checked) {
                    triggerForm();
                } else {
                    closeTooltip();
                }
            });
            
            const beforeFormSubmit = () => {
                if (to.value == to.max && from.value == from.min) {
                    from.disabled = true;
                    to.disabled = true;
                    currensys.forEach(function (currensy) {
                        currensy.disabled = true;
                    });
                }
            }

            const afterFormSubmit = () => {
                inputs.forEach(function (input, handle) {
                    input.disabled = false;
                });

                currensys.forEach(function (currensy) {
                    currensy.disabled = false;
                });
            }

            $(form).on('submit', () => beforeFormSubmit());

            $('.bd-filters-cat-tooltip__btn').on('click', (event) => {
                event.preventDefault();
                $(form).trigger('submit');
            });

            const triggerForm = () => {
                beforeFormSubmit();
                $(window).trigger('filter.change', [form]);
                afterFormSubmit();
            }

            const changeCoordinatesTooltip = function(input) {
                let label = input.closest('label');
                let topOffset = $(label).offset().top - $(wrap).offset().top;

                $(tooltip).css('top', topOffset + 'px');
            };
        }
    });
    
        
    
});