<template>
  <svg v-bind:class="addClass" v-bind="addAttributes" @click="$emit('click')">
    <use :xlink:href="resolvePath+'#'+id"></use>
  </svg>
</template>

<script>
export default {
  name: 'iconsvg',
  props: {
    'path': [ String, Number ],
    'id':   [ String, Number ],
    'addClass': {
      type: [Array, String],
      default: function() { return []}
    },
    'addAttributes': {
      type: [Array, String],
      default: function() { return []}
    },
  },
  computed: {
    resolvePath()
    {
      return this.path ? this.path : '/img/sprites/sprite.svg';
    }
  },
  data() {
    return {

    };
  }
}
</script>