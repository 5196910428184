$(document).ready(function(){

    $(".bd-s-banner__list").slick({
        rows: 0,
        infinite: false,
        slidesToShow: 1,
        // autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: $(".bd-slick-arrows .slick-prev"),
        nextArrow: $(".bd-slick-arrows .slick-next"),
    });
    
    $(".bd-card-main .bd-card-products__list--carusel").each(function() {
      let that = this;
      
      $(that).slick({
          rows: 0,
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          // autoplay: true,
          autoplaySpeed: 4000,
          prevArrow: $(that).parent().find(".bd-card-products__list--carusel-arrows .slick-prev"),
          nextArrow: $(that).parent().find(".bd-card-products__list--carusel-arrows .slick-next"),
          responsive: [
              {
                  breakpoint: 1550,
                  settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
  //                    variableWidth: true,
                  }
              },
              {
                  breakpoint: 1450,
                  settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4,
  //                    variableWidth: true,
                  }
              },
              {
                  breakpoint: 1300,
                  settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
  //                    variableWidth: true,
                  }
              },
              {
                  breakpoint: 910,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
  //                    variableWidth: true,
                  }
              },
              {
                  breakpoint: 590,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
  //                    variableWidth: true,
                  }
              }
          ],
      });
    });
    
    $(".bd-s-products .bd-card-products__list--carusel, .bd-wrap__catalog .bd-card-products__list--carusel").each(function() {
        let that = this;

        $(that).slick({
            rows: 0,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            // autoplay: true,
            autoplaySpeed: 4000,
            prevArrow: $(that).parent().find(".bd-card-products__list--carusel-arrows .slick-prev"),
            nextArrow: $(that).parent().find(".bd-card-products__list--carusel-arrows .slick-next"),
            responsive: [
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
    //                    variableWidth: true,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
    //                    variableWidth: true,
                    }
                },
                {
                    breakpoint: 910,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
    //                    variableWidth: true,
                    }
                },
                {
                    breakpoint: 590,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
    //                    variableWidth: true,
                    }
                }
            ],
        });
    });
});