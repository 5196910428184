import closingOnOutsideClick from '%components%/close';
import debounce from '%functions%/wrappers';

$(document).ready(function () {
    const container = document.querySelector('[data-with-filters]');

    if (!container) return false;
    
    const filters = container.querySelector('[data-filters]');
    const insBefore = filters.nextElementSibling;
    const filterInPanel = container.querySelector('.bd-main .bd-filters-portable');
    const filterInHeader = document.querySelector('.bd-header-sticky .bd-filters-portable');
    const portableFilters = document.querySelectorAll('.bd-filters-portable');

    portableFilters.forEach(portableFilter => {
        const title = portableFilter.querySelector('.bd-filters-portable__title');
        
        title.querySelector("p").innerHTML = filters.dataset.filters;

        title.addEventListener('click', () => {
            portableFilter.classList.toggle('bd-show');

            if (!portableFilter.querySelector('.bd-filters')) {
                portableFilter.appendChild(filters);
            }
        });

        closingOnOutsideClick(portableFilter);
    });

    window.addEventListener('resize', appendFiltersInContainer);
    window.addEventListener('scroll', toggleFilterInHeaderByScrolling);

    function appendFiltersInContainer() {
        if (window.innerWidth > 1200) {
          if (insBefore) {
            container.insertBefore(filters, insBefore);
          } else {
            container.appendChild(filters);
          }
        }
    }

    function toggleFilterInHeaderByScrolling() {
        const filterInPanelTop = filterInPanel.getBoundingClientRect().bottom;
        const filterInPanelOffset = filterInPanel.classList.contains('bd-show') ? filters.offsetHeight : 0;
        
        if (filterInPanelTop < (-1)*filterInPanelOffset) {
            filterInHeader.classList.add('bd-show-in-header');
        } else {
            filterInHeader.classList.remove('bd-show-in-header');
            filterInHeader.classList.remove('bd-show');
        }
    }

    appendFiltersInContainer = debounce(appendFiltersInContainer, 50);
    toggleFilterInHeaderByScrolling = debounce(toggleFilterInHeaderByScrolling, 50);
    
    appendFiltersInContainer();
    toggleFilterInHeaderByScrolling();
});
