$(document).ready(function () {
    const tablesSpoiler = document.querySelectorAll('.bd-table--spoiler');
    if (tablesSpoiler.length === 0) return false;
    
    tablesSpoiler.forEach(table => {
        const spoilerHeads = table.querySelectorAll('tr td:first-child');

        spoilerHeads.forEach(head => {
            head.addEventListener('click', () => {
                head.closest('tr').classList.toggle('bd-table-spoiler-show')
            })
        })
    })
    
})

$(document).ready(function () {
    const tablesSpoiler = document.querySelectorAll('.table-design--spoiler');
    if (tablesSpoiler.length === 0) return false;
    
    tablesSpoiler.forEach(table => {
        const spoilerHeads = table.querySelectorAll('tr td:first-child');

        spoilerHeads.forEach(head => {
            head.addEventListener('click', () => {
                head.closest('tr').classList.toggle('active')
            })
        })
    })
    
})
