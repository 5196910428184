<template>
    <count
        v-model="product.quantity"
        @input="update"
        addClass='["bd-count--boyard"]'
        addClassWrap=""
    ></count>
</template>

<script>
import count from '../count/count.vue';
    
export default {
    name: 'productCount',
    components: {
        count
    },
    beforeMount: function () {

    },
    mounted: function () {
        
    },
    props: [
        'product',
        'addClass',
        'addClassWrap'
    ],
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        update() {
            this.$emit('update');
        }
    },
    data: function () {
        return {
            
        };
    }
}
</script>