var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("price", {
    attrs: {
      price: _vm.bag.getProductPrice(
        _vm.product,
        _vm.quantity ? _vm.quantity : null
      ),
      iso: _vm.product.currency,
      addClass: _vm.addClass,
      title: _vm.title,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }