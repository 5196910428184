import Product from '../models/product.js';
import CurrencyRow from '../models/currency.js';

export default class Currency 
{
    constructor(data)
    {
        this.loadData(data);
    }
    
    loadData(data)
    {
        this.url = data.url;
        this.current = data.current;
        
        this.currencys = [];
        this.rates = data.rates || {};
        
        for (let i in data.currencys) {
            this.currencys.push(new CurrencyRow(data.currencys[i]));
        }
    }
    
    change(iso)
    {
        let that = this;
        
        $.ajax({
            url: this.url,
            method: 'put',
            processData: true,
            data: {
                "currency": iso
            },
            complete(jqXHR) {
                let data = jqXHR.responseJSON;
                that.current = iso;
            }
        });
    }
    
    getCurrencyByIso(iso)
    {
        for (let i in this.currencys) {
            if (this.currencys[i].iso == iso) {
                return this.currencys[i];
            }
        }
        
        return null;
    }
    
    convertByIso(fromIso, toIso, price, quantity = 1)
    {
        price = parseFloat(price);
        
        return price*quantity*this.rates[fromIso][toIso];
    }
    
    convert(product, toIso)
    {
        return this.convertByIso(product.currency, toIso, product.price, product.quantity);
    }
    
    convertRounded(product, toIso)
    {
        return this.roundByIso(toIso, this.convertByIso(product.currency, toIso, product.price, product.quantity));
    }
    
    roundByIso(iso, price)
    {
        let currency = this.getCurrencyByIso(iso);
        
        return currency ? currency.round(price) : '';
    }
    
    formate(iso, price)
    {
        let currency = this.getCurrencyByIso(iso);
        
        return currency ? currency.formate(price) : '';
    }
    
    formateByIso(iso, price, digits = null)
    {
        let currency = this.getCurrencyByIso(iso);
        
        return currency ? currency.formate(price, digits) : '';
    }
    
    formateCurrent(price)
    {
        return this.formate(this.current, price);
    }
    
    convertFormated(product, toIso)
    {
        return this.formate(toIso, this.convert(product, toIso));
    }
    
    convertFormatedByIso(fromIso, toIso, price, quantity = 1)
    {
        return this.formate(toIso, this.convertByIso(fromIso, toIso, price, quantity));
    }
    
    convertFormatedCurrent(product)
    {
        return this.convertFormated(this.current, product);
    }
    
    triggerCurrent()
    {
        $(window).trigger('currency.current', [this.current]);
    }
}