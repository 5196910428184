var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bd-widget-basket-product",
      class: { "bd-hide": _vm.deleted },
    },
    [
      _c("img", {
        staticClass: "bd-widget-basket-product__img",
        attrs: { src: _vm.product.image },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "bd-widget-basket-product__title",
        domProps: { innerHTML: _vm._s(_vm.product.full_name) },
      }),
      _vm._v(" "),
      !_vm.hideCart
        ? _c("product-count", {
            attrs: { product: _vm.product },
            on: {
              update: function ($event) {
                return _vm.update()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("product-price", {
        attrs: { product: _vm.product, title: "Стоимость", bag: _vm.bag },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bd-widget-basket-product__remove",
          on: {
            click: function ($event) {
              return _vm.removeDelayed()
            },
          },
        },
        [
          _c("icon", {
            attrs: { icon: "trash-3", addClass: ["icon--inline"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("a", {
        staticClass: "bd-widget-basket-product__link",
        attrs: { href: _vm.product.url },
      }),
      _vm._v(" "),
      _vm.product.errors && _vm.product.errors.length
        ? _c(
            "div",
            {
              staticClass:
                "bd-widget-basket-product__errors alert alert-danger",
            },
            _vm._l(_vm.product.errors, function (error) {
              return _c("div", {
                key: error.text,
                staticClass: "bd-widget-basket-product__error",
                domProps: { innerHTML: _vm._s(error.text) },
              })
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }