import noUiSlider from "nouislider"
import wNumb from "wnumb"

$(document).ready(function(){

    const slidersWrap = document.querySelectorAll('.bd-filters-cat-item-price');

    if (!slidersWrap) return false;

    slidersWrap.forEach(sliderWrap => {
        const slider = sliderWrap.querySelector('.bd-filters-cat-item-price__slider');
        const from = sliderWrap.querySelector('.bd-filters-cat-item-price__input-range--from');
        const to = sliderWrap.querySelector('.bd-filters-cat-item-price__input-range--to');
        const currensys = sliderWrap.querySelectorAll('.bd-filters-cat-item-price__input-range--currency');
        const inputs = [from, to];
        
        const selectedCurrency = () => {
            let ret = null;
            
            currensys.forEach(function(currency){
                if (currency.checked) {
                    ret = currency.value;
                }
            });
            
            return ret ? String(ret).toLowerCase() : ret;
        }
        
        const selectedCurrencyMinMax = () => {
            let cur = selectedCurrency();
            
            slider.noUiSlider.updateOptions({
                range: {
                    'min': $(from).data(cur),
                    'max': $(to).data(cur),
                }
            });
            
            slider.noUiSlider.setHandle(0, $(from).data(cur));
            slider.noUiSlider.setHandle(1, $(to).data(cur));
            
            
            to.max = $(to).data(cur);
        }
        
        let selected = selectedCurrency();
        from.min = 0;
        to.max   = $(to).data(selected);
        
        noUiSlider.create(slider, {
            start: [$(from).val(), $(to).val()],
            connect: true,
            range: {
                'min': $(from).data(selected),
                'max': $(to).data(selected)
            },
            format: wNumb({
                decimals: 0
            }),
            tooltips: true,
        });
        
        $(currensys).on('change', () => {
            selectedCurrencyMinMax();
        });
        
        slider.noUiSlider.on('update', (values, handle) => {
            inputs[handle].value = values[handle];
        });

        inputs.forEach(function (input, handle) {
            input.addEventListener('change', function () {
                slider.noUiSlider.setHandle(handle, this.value);
            })
        })
    });

});
