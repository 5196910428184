$.extend($.expr[":"],  {
    "contains-ci": function(elem, i, match, array) 
	{
		return (elem.textContent || elem.innerText || $(elem).text() || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
	}
});

$.ajaxSetup({
    headers: {
        'accept': 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

import '%components%/loader';

import '%components%/header';
import '%components%/footer';

import '%components%/aside-links';
import '%components%/category-info';
import '%components%/count';
import '%components%/currency';
import '%components%/pagination';
import '%components%/print';
import '%components%/quick-view';
import '%components%/slick';
import '%components%/table';
import '%components%/widget';

import '%components%/s-banner';
import '%components%/s-faq';
import '%components%/s-info';

import '%calc%/calc';
import '%components%/by/by';
import '%components%/compare/compare';
import '%components%/contacts/contacts';
import '%components%/news/news';
import '%components%/personal/personal';
import '%components%/shop/shop';

import '%components%/card/card-main';
import '%components%/card/card-important';
import '%components%/card/card-slider';
import '%components%/card/card-tabs';

import '%components%/filter/filters-cat-item-price';
import '%components%/filter/filters-cat-item';
import '%components%/filter/filters-favorite';
import '%components%/filter/filters-portable';

import '%components%/panel/panel-sort';
import '%components%/panel/panel-view';

import '%components%/price/price-aside';
import '%components%/price/price-aside-promo';

import '%components%/product/product';

import '%components%/search/search-input';
import '%components%/search/search-list';

import '%components%/notification-modal';

import '%components%/ddd/ddd';