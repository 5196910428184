<template>
  <div class="bd-widget-basket-product" v-bind:class="{'bd-hide': deleted}">
    <img v-bind:src='product.image' class="bd-widget-basket-product__img"> 
    <div class="bd-widget-basket-product__title" v-html='product.full_name'></div>
    <product-count v-if='!hideCart' :product='product' @update='update()'></product-count>
    <product-price :product='product' title='Стоимость' :bag="bag"></product-price>
    <div class="bd-widget-basket-product__remove" @click="removeDelayed()">
      <icon icon="trash-3" :addClass='["icon--inline"]'></icon>
    </div>
    <a v-bind:href='product.url' class="bd-widget-basket-product__link"></a>
    <div class="bd-widget-basket-product__errors alert alert-danger" v-if="product.errors && product.errors.length">
      <div class="bd-widget-basket-product__error" :key="error.text" v-for="error in product.errors" v-html="error.text"></div>
    </div>
  </div>
</template>

<script>
  import productCount from '%components%/product/productCount.vue';
  import productPrice from '%components%/product/productPrice.vue';
  import icon from '%components%/icon.vue';

  export default {
    name: 'cartWidgetItem',
    components: {
      productPrice, productCount, icon
    },
    beforeMount: function () {

    },
    mounted: function () {

    },
    props: {
      bag: Object,
      product: Object,
      hideCart: {
        type: Boolean,
        default: false
      }
    },
    watch: {

    },
    computed: {

    },
    methods: {
      update()
      {
        let that = this;
        clearTimeout(that.timerId);

        that.timerId = setTimeout(function () {
          that.bag.update(that.product);
        }, 300);

      },
      removeDelayed()
      {
        let that = this;
        that.deleted = true;
        setTimeout(function () {
          that.bag.remove(that.product).then(function () {
            that.deleted = false;
          });
        }, 300);
      }
    },
    data: function () {
      return {
        deleted: false,
        timerId: null
      };
    }
  }
</script>