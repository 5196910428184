var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._b(
      {
        staticClass: "icon",
        class: _vm.addClass,
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      "span",
      _vm.addAttributes,
      false
    ),
    [
      _vm._t("default", function () {
        return [_c("iconsvg", { attrs: { id: _vm.icon } })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }