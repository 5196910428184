$(document).ready(function(){
    const footer = document.querySelector('.bd-footer');

    if (!footer) return false;

    const buttonsOpen = footer.querySelectorAll('.bd-footer-spoiler__btn-open');
    const buttonsClose = footer.querySelectorAll('.bd-footer-spoiler__btn-close');

    buttonsOpen.forEach(btn => {
        btn.addEventListener('click', () => {
            footer.classList.add('bd-footer--open')
        })
    });

    buttonsClose.forEach(btn => {
        btn.addEventListener('click', () => {
            footer.classList.remove('bd-footer--open')
        })
    });

});