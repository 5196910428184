<template>
    <div class="search-widget" v-bind:class='{ "active": isActive, "opened": isOpened }'>
        
        <div class="bd-wrap">
            <div class="search-widget__container">
                
                <div class="bd-main-content search-widget__content">
                    
                    <div class="search-widget__title d-xl-none">Поиск</div>
                    
                    <form class="search-widget__form d-xl-none bd-search-input bd-search-input--white bd-search-input--group" v-bind:action="search.url">
                        <div class="bd-search-input__top">
                            <input type="text" placeholder="Поиск" name="query" class="bd-search-input__input" v-model="search.querystring">
                            <img src="/img/svg/search.svg" class="bd-search-input__ico-find" v-if="!search.querystring.length">
                            <img src="/img/svg/close--gray.svg" class="bd-search-input__ico-find cursor-pointer" v-if="search.querystring.length" @click="clearQuerystring()">
                        </div>
                        <button type="submit" class="bd-search-input__button-enter">Найти</button>
                    </form>
                    
                    <div class="search-widget__taps d-xl-none" v-if="search.taps.length">
                        <span class="bd-button bd-button--more-white me-2 mb-2" v-for='(q, index) in searchTaps' @click="resolveGoto(q)">
                            {{q.tap}}
                        </span>
                    </div>
                    
                    <div class="search-widget__result overflow-auto">
                        
                        <div class="bd-search-input-result__wrap bd-search-input-result__wrap--empty bd-show text-center" v-if='!firstSend || (!sended && !search.products.length)'>
                            <p>Начните вводить поисковую фразу</p>
                        </div>
                        
                        <div class="bd-search-input-result__header search-widget__result-header search-widget__result-header--big" v-if='!firstSend && search.products.length'>
                          Популярные товары
                        </div>
                      
                        <div class="bd-search-input-result__header search-widget__result-header" v-if='firstSend && search.products.length'>
                            <span>Найдено: {{ padej(search.count, 'товар', 'товара', 'товаров') }}</span><a v-bind:href="search.url+'?query='+search.querystring">Показать все</a>
                        </div>
                        
                        <div class="bd-search-input-result__header search-widget__result-header2" v-if='sended && !search.products.length'>
                            <span>По вашему запросу ничего не найдено</span><!--a v-bind:href="search.url+'?query='+search.querystring">Показать все результаты</a-->
                        </div>
                        
                        <div class="search-widget__scroll d-none d-xl-flex">
                            <div class="search-widget__scroll-container-products" v-show="search.products.length" v-html="search.html"></div>
                        </div>
                        
                    </div>
                    
                    <div class="search-widget__advice">
                        
                        <div class="search-widget__header d-none d-xl-block" v-if="searchQuerys.length">История</div>
                        
                        <ul class="search-widget__query-list" v-if="searchQuerys.length">
                            <li class="search-widget__query-item" v-for='(q, index) in searchQuerys'>
                                <a class="search-widget__query-q" v-bind:href="resolveHref(q)" @click.prevent="resolveGoto(q, 'history')">
                                    <icon icon='icon-search-query' :addClass='["icon--inline"]'></icon>
                                    {{q}}
                                </a>
                                <icon icon='icon-close' :addClass='["icon--inline", "search-widget__query-close"]' @click="search.removeQuery(index)"></icon>
                            </li>
                        </ul>
                        
                        <div class="search-widget__header d-none d-xl-block" v-if="search.sts.length">Часто ищут</div>
                        
                        <ul class="search-widget__query-list" v-if="search.sts.length">
                            <li class="search-widget__query-item" v-for='(q, index) in searchSts'>
                                <a class="search-widget__query-q" @click="resolveGoto(q, 'st')" v-bind:href="resolveHref(q)">
                                    <icon icon='icon-search' :addClass='["icon--inline"]'></icon>
                                    {{q.st}}
                                </a>
                            </li>
                        </ul>
                        
                        <div class="search-widget__header d-none d-xl-block" v-if="search.tree.length">Найти в категории</div>
                        
                        <ul class="search-widget__query-list" v-if="search.tree.length">
                            <li class="search-widget__query-item" v-for='(q, index) in searchTree'>
                                <a class="search-widget__query-q" @click.prevent="resolveGoto(q, 'category')" v-bind:href="resolveHref(q)">
                                    <icon icon='icon-search-tree' :addClass='["icon--inline"]'></icon>
                                    {{q.name}}
                                </a>
                            </li>
                        </ul>
                        
                    </div>
                    
                    <icon icon='icon-close' :addClass='["icon--inline", "search-widget__query-close-big", "d-xl-none"]' @click="closeWidget()"></icon>
                    
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import searchProduct from './searchProduct.vue';
import padej from '../../functions/padej.js';

import icon from '../icon.vue';
import iconsvg from '../icon-svg.vue';

export default {
    name: 'searchWidgetModal',
    components: {
        searchProduct, icon, iconsvg
    },
    beforeMount: function () {

    },
    mounted: function () {
        let that = this;
        let container = that.$el.querySelector('.search-widget__scroll-container-products');
        
        container.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            container.scrollLeft += evt.deltaY;
        });
        
//        if (this.search.querystring.length > 2) {
//            this.update();
//        }
        
        if (this.search.querystring.length > 0) {
          this.firstSend = true;
          this.preloaded = true;
        }
        
        if (this.search.products.length) {
          this.sended = true;
        }
    },
    props: [],
    computed: {
        ...mapState({
            search: state => state.search,
    
            favorite: state => state.favorite,
            compare: state => state.compare,
            cart: state => state.cart,
            quickView: state => state.quickView,
            counter: state => state.counter,
            searchTaps()
            {
                return this.search.taps.slice(0, 3);
            },
            searchSts()
            {
                return this.search.sts.slice(0, 3);
            },
            searchTree()
            {
                return this.search.tree.slice(0, 3);
            },
            searchQuerys()
            {
                return this.search.search_queries.slice(0, 3);
            },
        }),
        isOpened() {
            return this.search.opened;
        },
        isActive() {
            
            let res = this.search.opened;
            
            for (let i of ['quickView', 'favorite', 'compare', 'cart']) {
                res = res && !this[i].opened;
            }
            
            return res;
        }
    },
    watch: {
        'search.opened': {
          deep: false,
          immediate: true,
          handler: function(newVal, oldVal) {
            let that = this;
            if (newVal && !that.preloaded) {
              that.loading = true;
              that.search.loadDefaultRecomendations().then(function() {
                that.preloaded = true;
                that.loading = false;
              });
            }
          }
        },
        'search.querystring': {
          deep: true,
          immediate: true,
          handler: function(newVal, oldVal) {
              this.sended = false;
//              this.search.opened = true;
              
              let oldString = new String(oldVal || '');
              let originalString = new String(this.search.originalstring || '');
              
              if (oldString != originalString && this.search.querystring.length > 2) {
                  this.update();
              }

              if (oldVal != this.search.originalstring) {
                this.search.originalstring = "";
              }
          }
        },
        loading() {
            $(this.$el).bnLoadBig(this.loading);
        }
    },
    methods: {
        resolveQuery(q)
        {
            return q.relatedSearch || q.st || q;
        },
        resolveHref(q)
        {
            if (q.redirectUrl) {
                return q.redirectUrl;
            } else if (q.url && q.slug) {
                return this.search.url+'?query='+this.search.querystring+'&category='+q.slug;
            } else {
                return this.search.url+'?query='+this.resolveQuery(q);
            }
        },
        resolveGoto(q, eventType)
        {
            this.search.saveQueryCookie(this.resolveQuery(q));
            
            if (eventType == 'st') {
                this.counter.quickSearchStClick(this.search.querystring, this.search.originalstring, this.resolveQuery(q));
            }
            
            if (eventType == 'history') {
                this.counter.quickSearchHistoryClick(this.search.querystring, this.search.originalstring, this.resolveQuery(q));
            }
            
            if (eventType == 'category' && q.name) {
                this.counter.quickSearchCategoryClick(this.search.querystring, this.search.originalstring, q.name);
            }
            
            window.location = this.resolveHref(q);
        },
        padej()
        {
            return padej(...arguments);
        },
        update()
        {
            let that = this;
            clearTimeout(that.timerId);

            that.timerId = setTimeout(function() {
                that.loading = true;
                that.search.update().then(function(data){
                    that.firstSend = true;
                    that.sended = true;
                    that.loading = false;
                    
                    that.counter.quickSearchResult(that.search.querystring, that.search.originalstring, that.search.products, that.search.sts)
                });
            }, 1500);
        },
        closeWidget()
        {
            this.search.opened = false;
        },
        clearQuerystring() {
          this.search.querystring = '';
        }
    },
    data: function () {
        return {
            html: '',
            timerId: null,
            sended: false,
            loading: false,
            firstSend: false,
            preloaded: false
        };
    }
}
</script>