<template>
    <div class="bd-count" v-bind:class='addClass'>
        <div class="bd-count__wrap" v-bind:class='addClassWrap'>
          
            <span v-if='!disabled' class="bd-count__minus">-</span>
            <div v-if='disabled' class="bd-count__minus"></div>
            
            <input v-if='!disabled' type="text" v-model="propModel" :data-step='step' autocomplete="off" class="bd-count__value">
            <div v-if='disabled' v-html="propModel" class="bd-count__value"></div>
            
            <div v-if="measurement" class="bd-count__measurement">{{ measurement }}</div>
            
            <span v-if='!disabled' class="bd-count__plus">+</span>
            <div v-if='disabled' class="bd-count__plus"></div>
            
        </div>
        <!--<p class="bd-count__limit bd-hide">
            Доступно для заказа со склада:
            <span class="bd-count__limit-value"> 5</span>
        </p>-->
    </div>
</template>

<script>
export default {
    name: 'productCount',
    components: {

    },
    beforeMount: function () {

    },
    mounted: function () {
        
    },
    props: {
        'measurement': [ String, Number ],
        'value': [ String, Number ],
        'step':  {
            type: [String, Number],
            default: 1
        },
        'addClass': [Array, String],
        'addClassWrap': [Array, String],
        'disabled': {
            type: Boolean,
            default: false
        }
    },
    computed: {
        propModel: {
            get () {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    data: function () {
        return {
            
        };
    }
}
</script>