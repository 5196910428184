var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bd-search-input-result" }, [
    _c("div", { staticClass: "bd-search-input-result__container" }, [
      !_vm.firstSend || (!_vm.sended && !_vm.search.products.length)
        ? _c(
            "div",
            {
              staticClass:
                "bd-search-input-result__wrap bd-search-input-result__wrap--empty bd-show",
            },
            [_c("p", [_vm._v("Начните вводить поисковую фразу")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sended && !_vm.search.products.length
        ? _c(
            "div",
            {
              staticClass:
                "bd-search-input-result__wrap bd-search-input-result__wrap--result bd-show",
            },
            [
              _c("div", { staticClass: "bd-search-input-result__header" }, [
                _c("span", [_vm._v("Товаров не найдено.")]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.search.url + "?query=" + _vm.search.querystring,
                    },
                  },
                  [_vm._v("Показать все результаты")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.search.products.length
        ? _c(
            "div",
            {
              staticClass:
                "bd-search-input-result__wrap bd-search-input-result__wrap--result bd-show",
            },
            [
              _c("div", { staticClass: "bd-search-input-result__header" }, [
                _c("span", [
                  _vm._v(
                    "Найдено: " +
                      _vm._s(
                        _vm.padej(
                          _vm.search.count,
                          "товар",
                          "товара",
                          "товаров"
                        )
                      )
                  ),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.search.url + "?query=" + _vm.search.querystring,
                    },
                  },
                  [_vm._v("Показать все")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "bd-search-input-result__list" },
                _vm._l(_vm.search.products, function (product) {
                  return _c("search-product", {
                    key: "product" + product.id,
                    attrs: { product: product },
                  })
                }),
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }