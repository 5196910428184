export default class CurrencyRow {
    constructor(data) {
        let that = this;
    
        that.iso = data.iso;
        that.name = data.name;
        that.template = data.template;
        that.digits = data.digits;
        that.symbol = data.symbol;
    }
    
    formate(price, digits = null)
    {
        let str = new String(this.template);
        
        return str.replace('%s', $.number(this.round(price), digits === null ? this.digits: digits, ',', ' '));
    }
    
    round(price)
    {
        return parseFloat(price.toFixed(this.digits));
    }
}