(function ( $ ) {
 
    $.fn.bnLoad = function(show) {
        let that = this;
        
        if (!$(that).is('[data-loader-small]')) {
            $(that).attr('data-loader-small', true);
        }
        
        if (show === true) {
            $(that).addClass('bd-show-loader-small');
        } else if (show === false) {
            $(that).removeClass('bd-show-loader-small');
        } else {
            $(that).toggleClass('bd-show-loader-small');
        }
        
        return this;
    };
    
    $.fn.bnLoadBig = function(show) {
        let that = this;
        
        let template = "<div class='bd-loader-big'><div class='bd-loader-big__circle'></div><div class='bd-loader-big__center'></div></div>";
        
        if (!$(that).children('.bd-loader-big').length) {
            $(that).append(template);
        }
        
        let loader = $(that).children('.bd-loader-big');
        
        if (show === true) {
            $(loader).addClass('bd-show-loader-big');
        } else if (show === false) {
            $(loader).removeClass('bd-show-loader-big');
        } else {
            $(loader).toggleClass('bd-show-loader-big');
        }
        
        return this;
    };
 
}( jQuery ));
