$(document).ready(function(){
    
    const changeActiveCurrency = function(currency) {
        $('.bd-currency__value')
            .removeClass('bd-active')
            .filter(function() {
                return $(this).data('value') == currency;
            })
            .addClass('bd-active');
        
        $('.bd-price__current')
            .removeClass('bd-show')
            .filter(function() {
                return $(this).data('current') == currency;
            })
            .addClass('bd-show');
    }
    
    $(document).on('click', '.bd-currency__value', function(event) {
        let checkedValue = $(this).data('value');
        
        changeActiveCurrency(checkedValue);
        
        $(window).trigger('currency.change', [checkedValue]);
    });
    
    $(window).on('currency.current', changeActiveCurrency);
    
});