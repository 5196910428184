export default function padej($number, $padej1, $padej2, $padej3, $show_with_number) {
  
  $show_with_number = $show_with_number || true;
  
  var last_char = new String($number);
  last_char = last_char.substring(last_char.length-1, last_char.length);
  
  if (($number>=5 && $number<=20) || ($number > 20 && last_char >= 5 && last_char <= 9) || last_char == 0) {
		return (($show_with_number)?$number+" ":"")+$padej3;
	} else if (last_char == 1) {
		return (($show_with_number)?$number+" ":"")+$padej1;
	} else {
		return (($show_with_number)?$number+" ":"")+$padej2;
	}
}