import "%components%/card/card-tabs";
import {hideHeadsAndBodies} from "%components%/card/card-tabs";
import {initCardSliders} from "%components%/card/card-slider";

$(document).ready(function () {

    // Open tab by click
    
    const openTabByClick = (wrap, tab) => {
        const wrapDOM = document.querySelector(wrap);
        if (!wrapDOM) return false;

        const head = document.querySelector(`[data-tab-head='${tab}']`);
        const body = document.querySelector(`[data-tab-body='${tab}']`);
        if (!head) return false;

        wrapDOM.addEventListener('click', () => {
          
            if (window.innerWidth > 1200) {
                hideHeadsAndBodies();
            }

            head.classList.add('bd-show');
            body.classList.add('bd-show');

            body.scrollIntoView({
                block: "center"
            });
        })
    };

    openTabByClick('.bd-card-info .bd-product-list__toggle', '3');
    openTabByClick('.bd-card-info .bd-card-important', 'imp');
});

$(window).on('load', function(){
    initCardSliders($('.bd-card-slider'));
});