$(document).ready(function () {
    
    let actions = ['open', 'toggle', 'close'];
    
    for (let i in actions) {
        
        $(document).on('click', '[data-'+actions[i]+'-widget]', function(event) {
            event.preventDefault();
            let widget = $(this).data(actions[i]+'-widget');

            $(window).trigger(actions[i]+'.widget', [widget]);
        });
        
        $(document).on('click', '[href="#feedback-'+actions[i]+'-widget"]', function(event) {
            event.preventDefault();
            
            $(window).trigger(actions[i]+'.widget', ['feedback']);
        });

        $(document).on('click', '[data-'+actions[i]+'-header]', function(event) {
            event.preventDefault();
            let widget = $(this).data(actions[i]+'-header');

            $(window).trigger(actions[i]+'.header', [widget]);
        });
    }
    
    let spoilers = document.querySelectorAll('.bd-show-hide');

    spoilers.forEach(spoiler => {
        let spoilersTitle = spoiler.querySelector('.bd-show-hide__title');
        spoilersTitle.addEventListener('click', () => {
            spoiler.classList.toggle('bd-show')
        });
    });

});
