const closingOnOutsideClick = (container, className = 'bd-show') => {

    window.addEventListener('click', e => {
        if (!container.contains(e.target)) {
            container.classList.remove(className);
        }
    });

};

export default closingOnOutsideClick;
