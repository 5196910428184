export default class Counter
{
    productDetail(product)
    {
        let that = this;
        
        that.useTry(() => {
            window.carrotquest.track('$product_viewed', {
                '$name': product.name,
                '$url':  product.url,
                '$amount': product.totalPrice(),
                '$img': product.image
            });
            window.carrotquest.identify([
                { op: "union", key: "$viewed_products", value: product.name }
            ]);
        });
        
        that.useTry(() => {
            that.yandexSingleAction('detail', product, 1);
        });
    }
    
    productDetailAction(product, action)
    {
        let that = this;
        
        for (let ymAction of ['click3dbutton', 'click3dtab', 'feature', 'description', 'document', 'availability']) {
            if (action == ymAction) {
                that.useTry(() => {
                    window.ym(3171073, 'reachGoal', action);
                });
            }
        }
    }
    
    orderActionExecuted = [];
    
    orderAction(action)
    {
      let that = this;
        
        for (let ymAction of ['tip_pokupatelya_pay', 'dostavka_pay', 'user_pay', 'order_pay', 'order']) {
            if (action == ymAction && !that.orderActionExecuted.includes(action)) {
              
                that.yandexReachGoal(action);
                
                that.orderActionExecuted.push(action);
            }
        }
    }
    
    productList(products)
    {
        let that = this;
        
        that.useTry(() => {
            that.yandexAction('impression', products);
        });
    }
    
    cartAdd(cart, p, deffered)
    {
        let that = this;
        let pr = cart.getById(p.id);
        let quantity = pr ? pr.quantity : 0;
        
        deffered.then(function() {
            let product = cart.getById(p.id);
            
            if (product) {
                that.useTry(() => {
                    window.carrotquest.track('$cart_added', {
                        '$name':   product.name,
                        '$url':    product.url,
                        '$amount': product.totalPrice(),
                        '$img':    product.image
                    });
                    that.carrotCartStatus(cart);
                });
                
                that.useTry(() => {
                    that.yandexSingleAction('add', product, quantity);
                });
                
                that.yandexReachGoal('basket');
            }
        });
    }
    
    cartChange(cart, p, deffered)
    {
        let that = this;
        let pr = cart.getById(p.id);
        let quantity = pr ? pr.quantity : 0;
        
        deffered.then(function() {
            let product = cart.getById(p.id);
            
            if (product) {
                that.useTry(() => {
                    window.carrotquest.track('$cart_added', {
                        '$name':   product.name,
                        '$url':    product.url,
                        '$amount': product.totalPrice(),
                        '$img':    product.image
                    });
                    that.carrotCartStatus(cart);
                });
                
                that.useTry(() => {
                  if (product.quantity - quantity > 0) {
                    that.yandexSingleAction('remove', product, product.quantity - quantity);
                  } else {
                    that.yandexSingleAction('add', product, quantity - product.quantity);
                  }
                });
            }
        });
    }
    
    cartDelete(cart, p, deffered)
    {
        let that = this;
        
        let product = cart.getById(p.id);
        let quantity = product ? product.quantity : 0;
        
        deffered.then(() => {
            
            if (product) {
                that.useTry(() => {
                    window.carrotquest.track('Удалил товар из корзины', {
                        '$name': product.name
                    });
                    that.carrotCartStatus(cart);
                });
                
                that.useTry(() => {
                    that.yandexSingleAction('remove', product, quantity);
                });
            }
        });
    }
    
    orderStart(cart)
    {
        let that = this;
        
        that.useTry(() => {
            window.carrotquest.track('$order_started');
            that.carrotCartStatus(cart);
        });
    }
    
    orderFinish(cart, id)
    {
        let that = this;
        
        that.useTry(() => {
            window.carrotquest.track('$order_completed', {
                '$order_amount': cart.totalPrice(),
                '$order_id': id
            });
            window.carrotquest.identify([
                { op: "delete", key: "$cart_items", value: 0 },
                { op: "delete", key: "$viewed_products", value: 0 },
                { op: "delete", key: "$cart_amount", value: 0 },
                { op: "add", key: "$revenue", value: cart.totalPrice() },
                { op: "add", key: "$orders_count", value: 1 },
                { op: "update_or_create", key: "$last_payment", value: cart.totalPrice() }
            ]);
        });
        
        that.useTry(() => {
            that.yandexAction('purchase', cart.products, {
                id: id
            });
        });
    }
    
    sendManagerId(id)
    {
        let that = this;
        
        that.useTry(() => {
            window.carrotquest.identify([
                { op: "update_or_create", key: "assigned_user_id", value: id }
            ]);
        });
    }
    
    carrotCartStatus(cart)
    {
        if (cart.products.length) {
            window.carrotquest.identify([
                { op: "update_or_create", key: "$cart_items",  value: cart.products.map((p) => p.name) },
                { op: "update_or_create", key: "$cart_amount", value: cart.totalPrice()},
            ]);
        } else {
            window.carrotquest.identify([
                { op: "delete", key: "$cart_items",  value: 0 },
                { op: "delete", key: "$cart_amount", value: 0 },
            ]);
        }
    }
    
    yandexSingleAction(action, product, quantity)
    {
        let data = {
            ecommerce: {
                currencyCode: "RUB"
            }
        };
        
        data.ecommerce[action] = {
            products: [{
                name: product.name,
                price: product.getBasePrice(),
                quantity: quantity,
                category: product.type_name,
            }]
        }
        
        window.dataLayer.push(data);
    }
    
    yandexAction(action, products, actionField = null)
    {
        let data = {
            ecommerce: {
                currencyCode: "RUB"
            }
        };
        
        let productData = [];
        
        for(let product of products) {
            productData.push({
                name: product.name,
                price: product.getBasePrice(),
                quantity: product.quantity,
                category: product.type_name,
            });
        }
        
        data.ecommerce[action] = {
            products: productData
        }
        
        if (actionField) {
            data.ecommerce[action].actionField = actionField;
        }
        
        window.dataLayer.push(data);
    }
    
    useTry(callback)
    {
        try {
            callback();
        } catch (e) {
            
        }
    }
    
    quickSearchResult(query, originalQuery, products, sts)
    {
        let that = this;
        
        that.useTry(() => {
            
            let ids = [];
            
            for (let product of products) {
                ids.push(product.name);
            }
            
            window.Digi.api.sendSearchEvent({
                searchTerm:   that.resolveQuery(query, originalQuery),
                correction:   that.resolveCorrection(query, originalQuery),
                pageProducts: ids,
                refinements:  sts
            });
        });
    }
    
    quickSearchStClick(query, originalQuery, st)
    {
        let that = this;
        
        that.useTry(() => {
            window.Digi.api.sendAutocompleteQueryClickEvent({
              searchTerm: that.resolveQuery(query, originalQuery),
              correction: that.resolveCorrection(query, originalQuery),
              item: st
            });
        });
    }
    
    searchTapClick(query, originalQuery, tap, tapArray)
    {
        let that = this;
        
        that.useTry(() => {
            window.Digi.api.sendRefinementClickEvent({
              searchTerm: that.resolveQuery(query, originalQuery),
              correction: that.resolveCorrection(query, originalQuery),
              item: tap,
              refinements: tapArray
            });
        });
    }
    
    quickSearchHistoryClick(query, originalQuery, st)
    {
        let that = this;
        
        that.useTry(() => {
            window.Digi.api.sendAutocompleteHistoryClickEvent({
              searchTerm: that.resolveQuery(query, originalQuery),
              correction: that.resolveCorrection(query, originalQuery),
              item: st
            });
        });
    }
    
    quickSearchCategoryClick(query, originalQuery, name)
    {
        let that = this;
        
        that.useTry(() => {
            window.Digi.api.sendAutocompleteCategoryClickEvent({
              searchTerm: that.resolveQuery(query, originalQuery),
              correction: that.resolveCorrection(query, originalQuery),
              item: name
            });
        });
    }
    
    quickSearchProductClick(query, originalQuery, product)
    {
        let that = this;
        
        that.useTry(() => {
            window.Digi.api.sendAutocompleteProductClickEvent({
              searchTerm: that.resolveQuery(query, originalQuery),
              correction: that.resolveCorrection(query, originalQuery),
              item:      product.name,
              productId: product.name
            });
        });
    }
    
    resolveQuery(query, originalQuery)
    {
      return query;
    }
    
    resolveCorrection(query, originalQuery)
    {
      return originalQuery ? originalQuery : query;
    }
    
//    searchProductClick(query, originalQuery, product)
//    {
//        let that = this;
//        
//        that.useTry(() => {
//            window.Digi.api.sendProductPageViewEvent({
//              searchTerm: query,
//              correction: query,
//              item:      product.name,
//              productId: product.name
//            });
//        });
//    }
//    
//    searchFilterToggle(name, position, shown)
//    {
//      let that = this;
//        
//        that.useTry(() => {
//            window.Digi.api.sendProductPageViewEvent({
//              strategy: "",
//              position: position,
//              item:     name,
//              type:     name,
//              status:   shown
//            });
//        });
//    }

    feedbackSend(themeSlug, data)
    {
      this.yandexReachGoal('feedback-'+themeSlug);
    }
    
    yandexReachGoal(goal)
    {
      let that = this;
      
      that.useTry(() => {
          window.ym(3171073, 'reachGoal', goal);
      });
    }
}