import closingOnOutsideClick from "%components%/close";

$(document).ready(function () {
    const $slider = $(".bd-by-history-slider");

    $slider.slick({
        infinite: false,
        slidesToShow: 1,
        // autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 4000,
    });

    // Toggle list on mob

    const historyTime = document.querySelector(".bd-by-history-time");

    if (!historyTime) return false;

    const list = historyTime.querySelector(".bd-by-history-time__list");
    const selectedText = historyTime.querySelector(".bd-by-history-time__selected span");

    historyTime.addEventListener("click", () => {
        historyTime.classList.toggle("bd-show");
    });

    list.addEventListener("click", e => {
        e.stopPropagation();
        const value = e.target.dataset.year;
        if (value) {
            selectedText.textContent = value;
            historyTime.classList.remove("bd-show");
        }
    });

    closingOnOutsideClick(historyTime);

    // History & slider

    const activeItems = document.querySelectorAll(".bd-by-history-time__item.bd-active");

    const getSlideIndex = year => {
        const slideContent = [...$slider.find(`[data-year-slider="${year}"]`)][0];
        return slideContent.closest('.slick-slide').dataset.slickIndex;
    };

    activeItems.forEach(activeItem => {
        activeItem.addEventListener("click", e => {
            const year = activeItem.querySelector(".bd-by-history-time__title").dataset.year;
            const slideIndex = getSlideIndex(year);
            $slider.slick('slickGoTo', slideIndex);
        });
    });
    
    $slider.on('afterChange', function (event, slick, currentSlide) {
        const slider = document.querySelector('.bd-by-history-slider');
        const currentSlideDOM = slider.querySelector(`[data-slick-index="${currentSlide}"]`);
        const year = currentSlideDOM.querySelector("[data-year-slider]").dataset.yearSlider;
        selectedText.textContent = year;
    })

});
