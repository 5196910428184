export function initCardSliders(container) {
    $(container).find('.bd-card-slider__slider-small').each(function () {
        $(this).slick({
            infinite: true,
            slidesToShow: 4,
            focusOnSelect: true,
            // autoplay: true,
            autoplaySpeed: 4000,
            asNavFor: $(this).siblings('.bd-card-slider__slider-big'),
            vertical: true,
            verticalSwiping: true,
            centerMode: true,
        });
    });


    $(container).find(".bd-card-slider__slider-big").each(function () {
        $(this).slick({
            infinite: true,
            slidesToShow: 1,
            // autoplay: true,
            autoplaySpeed: 4000,
            asNavFor: $(this).siblings('.bd-card-slider__slider-small'),
            arrows: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: true
                    }
                },
            ]
        });
    });
    
    $(container).find("[data-fancybox='gallery']").fancybox({
        hash: false,
        beforeClose: function(instance) {
            const indexFancybox = instance.currIndex - 1;
            $('.bd-card-slider__slider-big').slick('slickGoTo', indexFancybox);
            $('.bd-card-slider__slider-small').slick('slickGoTo', indexFancybox)
        },
    });
    
    $(container).find('.bd-card-slider__img--3d').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const head = document.querySelector('.bd-card-tabs__head--3d');
        const body = document.querySelector('.bd-card-tabs__body--3d');
        const event = new Event('click');
        
        head.dispatchEvent(event);
        body.scrollIntoView({
            block: "center"
        });
        
        $(window).trigger('product.detail.action', ['click3dbutton']);
    })
};