import debounce from "%functions%/wrappers";

$(document).ready(function () {
    
    let loading = false;
    let timerId = null;
    
    $('.bd-filters-favorite :checkbox').on('change', function(event) {
        let container = $('.bd-filters-favorite')[0];
        let target = this;
        let all = $(container).find('.bd-filters-favorite__all')[0];
        let allCheckbox = $(container).find('.bd-filters-favorite__all :checkbox')[0];
        let items = $(container).find('.bd-filters-favorite__item');
        let itemCheckboxes = $(container).find('.bd-filters-favorite__item :checkbox');
        
        
        if (target == allCheckbox) {
            itemCheckboxes.prop('checked', $(target).is(':checked'));
            if ($(target).is(':checked')) {
                $(container).addClass('bd-filters-favorite--all')
            } else {
                $(container).removeClass('bd-filters-favorite--all')
            }
        } else {
            if (items.length == $(itemCheckboxes).filter(':checked').length) {
                $(allCheckbox).prop('checked', true);
                $(container).addClass('bd-filters-favorite--all')
            } else {
                $(allCheckbox).prop('checked', false);
                $(container).removeClass('bd-filters-favorite--all')
            }
        }
        
        let title = [];
        
        let checkedItems = $(items)
            .removeClass('bd-highlight')
            .filter(':has(:checked)')
            .addClass('bd-highlight')
            .each(function(){
                title.push($(this).find('.bd-f-checked__text').html());
            });
        title = title.join(', ') || $(container).data('filters');
        
        if (checkedItems.length == 0) {
            title = $(container).data('filters');
        } else if (checkedItems.length == items.length) {
            title = $(all).find('.bd-f-checked__text').html();
        }
        
        $('.bd-filters-portable__title p').each(function() {
            $(this).html(title);
        })
        
        if (checkedItems.length) {
            loadMoreContent();
        }
    });
    
    $('.bd-filters-portable__title p').each(function() {
        $(this).html($('.bd-filters-favorite').data('filters'));
    })
    
    const loadMoreContent = debounce(() => {
        $('.bd-filters-favorite').submit();
    }, 1000);
    
});
