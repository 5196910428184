<template>
    <price :price="bag.getProductPrice(product, quantity ? quantity : null)" :iso="product.currency" :addClass="addClass" :title="title"></price>
</template>

<script>
import { mapState } from 'vuex';

import price from '../price/price.vue';

export default {
    name: 'productPrice',
    components: {
        price
    },
    beforeMount: function () {

    },
    mounted: function () {
        
    },
    props: ['product', 'title', 'addClass', 'quantity', 'bag'],
    computed: {
        ...mapState({
            currency: state => state.currency
        })
    },
    methods: {
        
    },
    data: function () {
        return {
            
        };
    }
}
</script>