import Vue from 'vue/dist/vue.common.js';

export default {
    state: {
        
    },
    getters: {},
    mutations: {
        setModule(state, payload) {
            Vue.set(state, payload.name, payload.object);
        },
        setProperty(state, payload) {
            Vue.set(state[payload.module], payload.name, payload.value);
        }
    },
    actions: {
        
    },
    modules: {
        
    }
};