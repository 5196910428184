var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["bd-price", _vm.addClass ? _vm.addClass : ""] }, [
    _vm.title
      ? _c("p", {
          staticClass: "bd-price__text",
          domProps: { innerHTML: _vm._s(_vm.title) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "bd-price__currents" },
      _vm._l(_vm.currency.currencys, function (curr) {
        return _c("li", {
          staticClass: "bd-price__current",
          class: { "bd-show": _vm.currency.current == curr.iso },
          attrs: { "data-current": curr.iso },
          domProps: {
            innerHTML: _vm._s(
              _vm.currency.convertFormatedByIso(_vm.iso, curr.iso, _vm.price)
            ),
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }