import Vue from 'vue/dist/vue.common.js';
import Vuex from 'vuex';
import { mapState } from 'vuex';
import store from '%store%/index.js';

import searchWidget from '%components%/search/searchWidget.vue';
import searchWidgetModal from '%components%/search/searchWidgetModal.vue';
import cartWidget from '%components%/bag/cartWidget.vue';
import favoriteWidget from '%components%/bag/favoriteWidget.vue';
import compareWidget from '%components%/bag/compareWidget.vue';
import headerLocation from '%components%/location/headerLocation.vue';
import loginWidget from '%components%/user/loginWidget.vue';

import Header   from '%modules%/header.js';
import Search   from '%modules%/search.js';
import Cart     from '%modules%/cart.js';
import Favorite from '%modules%/favorite.js';
import Compare  from '%modules%/compare.js';
import Currency from '%modules%/currency.js';
import Layout   from '%modules%/layout.js';
import Location from '%modules%/location.js';
import Filter   from '%modules%/filter.js';
import Form     from '%modules%/form.js';
import Feedback from '%modules%/feedback.js';
import Share    from '%modules%/share.js';
import Counter  from '%modules%/counter.js';
import User     from '%modules%/user.js';
import QuickView from '%modules%/quickView.js';
import MapPointPick from '%modules%/mapPointPick.js';
import Modal from '%modules%/modal.js';
import padej from '%functions%/padej.js';
import dadataDirective from '%directives%/dadata.js';

Vue.use(Vuex);
Vue.directive('dadata', dadataDirective);

new Vue({
    el: '#app',
    store: new Vuex.Store(store),
    components: {
        searchWidget,
        searchWidgetModal,
        cartWidget,
        favoriteWidget,
        compareWidget,
        headerLocation,
        orderCart:         () => import(/* webpackChunkName: "order_cart" */  '%components%/order/orderCart.vue'),
        orderAside:        () => import(/* webpackChunkName: "order_aside" */ '%components%/order/orderAside.vue'),
        order:             () => import(/* webpackChunkName: "order" */       '%components%/order/order.vue'),
        feedbackWidget:    () => import(/* webpackChunkName: "feedback" */    '%components%/feedback/feedbackWidget.vue'),
        shareWidget:       () => import(/* webpackChunkName: "share" */       '%components%/shareWidget.vue'),
        filterWidget:      () => import(/* webpackChunkName: "filter" */      '%components%/filter/filterWidget.vue'),
        pdfOffer:          () => import(/* webpackChunkName: "pdf_offer" */   '%components%/pdfOffer.vue'),
        loginWidget,
        registerWidget:       () => import(/* webpackChunkName: "register" */                '%components%/user/registerWidget.vue'),
        profileWidget:        () => import(/* webpackChunkName: "profile" */                 '%components%/user/profileWidget.vue'),
        claimWidget:          () => import(/* webpackChunkName: "claim" */                   '%components%/claim/claimWidget.vue'),
        contactMap:           () => import(/* webpackChunkName: "contact_map" */             '%components%/contacts/contactMap.vue'),
        simpleMap:            () => import(/* webpackChunkName: "simple_map" */              '%components%/simpleMap.vue'),
        counterProductList:   () => import(/* webpackChunkName: "counter_product_list" */    '%components%/counter/counterProductList.vue'),
        counterProductDetail: () => import(/* webpackChunkName: "counter_product_detail" */  '%components%/counter/counterProductDetail.vue'),
        counterSearchQuery:   () => import(/* webpackChunkName: "counter_search_query" */    '%components%/counter/counterSearchQuery.vue'),

        calculatorStart:     () => import(/* webpackChunkName: "calculator_start" */     '%calc%/calculatorStart.vue'),
        calculatorNeo:       () => import(/* webpackChunkName: "calculator_neo" */       '%calc%/calculatorNeo.vue'),
        calculatorPuzzle:    () => import(/* webpackChunkName: "calculator_puzzle" */    '%calc%/calculatorPuzzle.vue'),
        calculatorTurkish:   () => import(/* webpackChunkName: "calculator_turkish" */   '%calc%/calculatorTurkish.vue'),
        calculatorHinge:     () => import(/* webpackChunkName: "calculator_hinge" */     '%calc%/calculatorHinge.vue'),
        calculatorHingeTwo:  () => import(/* webpackChunkName: "calculator_hinge_two" */ '%calc%/calculatorHingeTwo.vue'),
        calculatorBplanum:   () => import(/* webpackChunkName: "calculator_bplanum" */   '%calc%/calculatorBplanum.vue'),
        calculatorExtra:     () => import(/* webpackChunkName: "calculator_extra" */     '%calc%/calculatorExtra.vue'),
        calculatorWeight:    () => import(/* webpackChunkName: "calculator_weight" */    '%calc%/calculatorWeight.vue'),
        calculatorBox:       () => import(/* webpackChunkName: "calculator_box" */       '%calc%/calculatorBox.vue'),
        calculatorCustomize: () => import(/* webpackChunkName: "calculator_customize" */ '%calc%/calculatorCustomize.vue'),
        
        facadeWidget:      () => import(/* webpackChunkName: "facade_widget" */ '%calc%/facadeWidget.vue'),
        hingeWidget:       () => import(/* webpackChunkName: "hinge_widget" */  '%calc%/hingeWidget.vue'),
    },
    watch: {
        'cart.products': function (val, oldVal) {
            this.triggerUpdate('cart');
        },
        'favorite.products': function (val, oldVal) {
            this.triggerUpdate('favorite');
        },
        'compare.products': function (val, oldVal) {
            this.triggerUpdate('compare');
        },
        'hasOverlay': function(newVal, oldVal) {

            if (newVal != oldVal) {
                if (newVal) {
                    $('body').addClass('bd-no-scroll');
                } else {
                    $('body').removeClass('bd-no-scroll');
                }
            }
        }
    },
    beforeMount() {
        this.cart.setCurrencyManager(this.currency);
        this.compare.setCurrencyManager(this.currency);
        this.favorite.setCurrencyManager(this.currency);
    },
    mounted: function() {
        let that = this;
        
        that.feedback.setFormManager(that.form);
        
        that.cart.registerCallback('add',    function() { that.counter.cartAdd(...arguments) });
        that.cart.registerCallback('change', function() { that.counter.cartChange(...arguments) });
        that.cart.registerCallback('delete', function() { that.counter.cartDelete(...arguments) });
        
        $(window).on('product.detail.action', function(event, action) {
            that.counter.productDetailAction(null, action);
        });
        
        if (window.viewmodel.manager_id) {
            $(window).on('load', function(){
                that.counter.sendManagerId(window.viewmodel.manager_id);
            });
        }
        
        $(window).on('load', function() {
            $.getScript("//platform-api.sharethis.com/js/sharethis.js#property=5993d57be2587a001253a254&product=custom-share-buttons");
        });

        $(window).on('open.widget', function(event, widgetName){
            that.openWidget(widgetName);
        });

        $(window).on('toggle.widget', function(event, widgetName){
            that.toggleWidget(widgetName);
        });

        $(window).on('toggle.header', function(event, widgetName){
            that.header.toggle(widgetName);
        });

        $(window).on('outside.city', function(){
            that.header.close('city');
        });

        $(window).on('cart.add', function(event, id, count) {
            that.cart.patchById(id, count);
            that.openWidget('cart');
        });

        $(window).on('cart.fresh', function(event, id, count) {
            that.cart.fresh();
            that.openWidget('cart');
        });

        $(window).on('favorite.toggle', function(event, id, count) {
            that.favorite.toggleById(id, count);
            that.openWidget('favorite');
        });

        $(window).on('compare.toggle', function(event, id, count) {
            that.compare.toggleById(id, count);
            that.openWidget('compare');
        });

        $(window).on('compare.remove.silent', function(event, id) {
            that.compare.removeById(id).then(function(){
                $(window).trigger('compare.remove.silented');
            });
        });
        
        $(window).on('compare.clear', function(event, id) {
            that.compare.clear().then(function() {
                $(window).trigger('compare.clear.silented');
            });
        });

        $(window).on('currency.change', function(event, iso) {
            that.currency.change(iso);
        });

        $(window).on('layout.change', function(event, layout) {
            that.layout.change(layout);
        });

        $(window).on('filter.change', function(event, form) {
            that.filter.updateStatus(form);
        });

        $(window).on('filter.tooltip', function(event, status) {
            that.filter.changeTooltip(status);
        });

        $(window).on('rebuild.catalog', function(event) {
            that.triggerUpdate('cart');
            that.triggerUpdate('favorite');
            that.triggerUpdate('compare');
        });

        $(window).on('location.confirmed', function(event) {
            that.location.confirm();
        });
        
        $(window).on('quick-view', function(event, data) {
            that.counter.productDetail(that.cart.buildProduct(data));
        });
        
        $(window).on('list-view', function(event, data) {
            that.counter.productList(that.cart.buildProducts(data));
        });
        
        $(document).on('click', '.search-widget__result .bd-product', function(event) {
            let container = $(event.target).closest('.bd-product');
            
            if (container.length && container.data('id') && container.data('name')) {
              that.counter.quickSearchProductClick(that.search.querystring, {
                id:   container.data('id'),
                name: container.data('name')
              });
            }
        });
        
//        $(document).on('click', '.bd-m-search .bd-product', function(event) {
//            let container = $(event.target).closest('.bd-product');
//            
//            if (container.length && container.data('id') && container.data('name')) {
//              that.counter.searchProductClick(that.search.querystring, {
//                id:   container.data('id'),
//                name: container.data('name')
//              });
//            }
//        });
        
        $(document).on('click', '.bd-m-search .bd-button--search-tap', function(event) {
            let container = $(event.target);
            let tapArray  = [];
            
            $('.bd-m-search .bd-button--search-tap').each(function() {
              tapArray.push($(this).data('tap'));
            })
            
            if (container.length) {
              that.counter.searchTapClick(that.search.querystring, that.search.originalstring, container.data('tap'), tapArray);
            }
        });
        
        window.addEventListener("keydown", e => e.code === "Escape" && this.resolveClose());
    },
    destroyed: function() {
        window.removeEventListener('mousemove', this.move);
    },
    computed: {
        hasOverlay() {
            let names = this.wigetNames();

            for (let i in names) {
                if (this[names[i]].opened) {
                    return true;
                }
            }

            return this.header.hasOpened();
        },
        hasOverlayMenu() {
            return this.header.openedCatalogMenu;
        },
        ...mapState({
            modal: state => state.modal,
            mapPointPick: state => state.mapPointPick,
            search: state => state.search,
            cart: state => state.cart,
            favorite: state => state.favorite,
            compare: state => state.compare,
            location: state => state.location,
            filter: state => state.filter,
            currency: state => state.currency,
            user: state => state.user,
            feedback: state => state.feedback,
            form: state => state.form,
            header: state => state.header,
            layout: state => state.layout,
            quickView: state => state.quickView,
            share: state => state.share,
            counter: state => state.counter,
            env: state => state.env,
        })
    },
    methods: {
        triggerEvent(event, params) {
            $(window).trigger(event, params);
        },
        resolveClose() {
            if (this.modal.hasOpened()) {
              this.modal.closeOne();
            }
            else if (this.cart.opened) {
                this.cart.opened = false;
            }
            else if (this.favorite.opened) {
                this.favorite.opened = false;
            }
            else if (this.compare.opened) {
                this.compare.opened = false;
            }
            else if (this.quickView.opened) {
                this.quickView.opened = false;
            }
            else if (this.search.opened) {
                this.search.opened = false;
            }
            else if (this.filter.opened) {
                this.filter.opened = false;
            }
            else if (this.header.openedCityMenu) {
                this.header.close('CityMenu');
            }
            else if (this.header.hasSubmenu()) {
                this.header.clearSubmenu();
            }
            else if (this.header.catalog || this.header.menu) {
                this.header.closeMenuAndCatalog();
            }
            else {
                this.closeAllWigets(true);
            }
        },
        wigetNames() {
            return ['cart', 'compare', 'favorite', 'feedback', 'user', 'search', 'quickView', 'share', 'filter', 'mapPointPick', 'modal'];
        },
        locationConfirm() {
            this.location.confirm();
            this.header.close('city');
        },
        toggleWidget(name) {
            let status = this[name] ? this[name].opened : false;
            
            this.header.closeMenuAndCatalog();
            this.closeAllWigets();
            if (this[name] && !status) {
                this[name].opened = true;
            }
        },
        openWidget(name) {
            this.header.closeMenuAndCatalog();
            this.closeAllWigets();
            if (this[name]) {
                this[name].opened = true;
            }
        },
        closeAllWigets(withModals = false) {
            let names = this.wigetNames();
            
            for (let i in names) {
                if ((names[i] != 'quickView' && names[i] != 'search') || withModals === true ) {
                    this[names[i]].opened = false;
                }
            }
        },
        triggerUpdate(moduleName) {
            $(window).trigger('catalog.'+moduleName+'.update', [this[moduleName].getIds()]);
        },
        padej($number, $padej1, $padej2, $padej3, $show_with_number) {
            return padej($number, $padej1, $padej2, $padej3, $show_with_number);
        }
    },
    beforeCreate: function() {
        let viewmodel = window.viewmodel || {};
        let currency = viewmodel.currency || {};
        let url = viewmodel.url || {};
        let data = {};
        
        data.localization = viewmodel.localization || {};

        this.$store.commit('setModule', {
            name:'header',
            object: new Header({})
        });

        this.$store.commit('setModule', {
            name:'cart',
            object: new Cart({
                url: url.cart || '/cart',
                products: viewmodel.cart || [],
                localization: data.localization
            })
        });

        this.$store.commit('setModule', {
            name:'favorite',
            object: new Favorite({
                url: url.favorite || '/favorite',
                products: viewmodel.favorite || [],
                localization: data.localization
            })
        });

        this.$store.commit('setModule', {
            name:'compare',
            object: new Compare({
                url: url.compare || '/compare',
                products: viewmodel.compare || [],
                localization: data.localization
            })
        });

        this.$store.commit('setModule', {
            name:'currency',
            object: new Currency({
                url: url.currency || '/currency',
                current: currency.current || null,
                currencys: currency.currencys || [],
                rates: currency.rates || {}
            })
        });

        this.$store.commit('setModule', {
            name:'layout',
            object: new Layout({
                url: url.layout || '/layout',
            })
        });

        this.$store.commit('setModule', {
            name:'location',
            object: new Location({
                url: url.location || '/city',
                location: viewmodel.location || [],
                localization: data.localization
            })
        });

        this.$store.commit('setModule', {
            name:'filter',
            object: new Filter({

            })
        });

        this.$store.commit('setModule', {
            name:'layout',
            object: new Layout({
                url: url.layout || '/layout',
            })
        });

        this.$store.commit('setModule', {
            name:'form',
            object: new Form({
                forms: viewmodel.forms || [],
                localization: data.localization
            })
        });

        this.$store.commit('setModule', {
            name:'feedback',
            object: new Feedback({
                url: url.feedback || '/feedback'
            })
        });
        
        this.$store.commit('setModule', {
            name:'share',
            object: new Share({
                
            })
        });
        
        this.$store.commit('setModule', {
            name:'counter',
            object: new Counter({
                
            })
        });
        
        this.$store.commit('setModule', {
            name:'env',
            object: {
                external: viewmodel.external || false
            }
        });
        
        this.$store.commit('setModule', {
            name:'user',
            object: new User({
                url: url.user || {
                    'login': '/login',
                    'register': '/register',
                    'recover': '/forgot-password',
                    'logout': '/logout'
                },
                user: viewmodel.user || {}
            })
        });
        
        if (!viewmodel.search.url) {
          viewmodel.search.url = '/search';
        }
        
        this.$store.commit('setModule', {
            name:'search',
            object: new Search(viewmodel.search)
        });

        this.$store.commit('setModule', {
            name:'quickView',
            object: new QuickView({

            })
        });
        
        this.$store.commit('setModule', {
            name:'mapPointPick',
            object: new MapPointPick({

            })
        });

        this.$store.commit('setModule', {
            name:'modal',
            object: new Modal({

            })
        });
    }
});

