<template>
    <div :class='["bd-price", addClass ? addClass : ""]'>
        <p class="bd-price__text" v-if="title" v-html="title"></p>
        <ul class="bd-price__currents">
            <li
                v-for='curr in currency.currencys'
                v-bind:data-current="curr.iso"
                v-html='currency.convertFormatedByIso(iso, curr.iso, price)'
                class="bd-price__current"
                v-bind:class="{'bd-show': currency.current == curr.iso}"></li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'price',
    components: {

    },
    beforeMount: function () {

    },
    mounted: function () {
        
    },
    props: ['price', 'iso', 'title', 'addClass'],
    computed: {
        ...mapState({
            currency: state => state.currency
        })
    },
    methods: {
        
    },
    data: function () {
        return {
            
        };
    }
}
</script>