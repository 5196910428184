import Widget from './widget.js';

export default class Header extends Widget
{
    constructor(data)
    {
        super();
        
        this.openedCity = false;
        this.openedCityMenu = false;
        this.openedSearch = false;
        
        this.menu = false;
        this.catalog = false;
        this.submenu = null;
        
        this.openedCatalogMenu = false;
        this.openedCatalogMenuMob = false;
        this.openedCatalogMenuSubcategory = false;
        this.openedCatalogMenuSubcategoryMob = false;
    }
    
    toggleMenu()
    {
        this.menu = !this.menu;
        
        if (this.menu == false) {
            this.catalog = false;
            this.submenu = null;
        }
    }
    
    toggleMenuWithLocation()
    {
        if (this.openedCityMenu) {
            this.close('CityMenu');
        } else {
            this.toggleMenu();
        }
    }
    
    openCatalog()
    {
        this.menu = true;
        this.catalog = true;
    }
    
    toggleCatalog()
    {
        this.menu = true;
        this.catalog = !this.catalog;
        
        if (!this.catalog) {
            this.submenu = null;
        }
    }
    
    openSubmenu(id)
    {
        this.submenu = id;
    }
    
    clearSubmenu()
    {
        this.submenu = null;
    }
    
    hasSubmenu()
    {
        return this.submenu !== null;
    }
    
    checkSubmenu(id)
    {
        return this.submenu == id;
    }
    
    closeCatalog()
    {
        this.catalog = false;
        this.submenu = null;
    }
    
    closeMenuAndCatalog()
    {
        this.menu = false;
        this.catalog = false;
        this.submenu = null;
    }
    
    toggle(key)
    {
        let name = this.propName(key);
        this[name] = !this[name];
    }
    
    open(key)
    {
        this[this.propName(key)] = true;
    }
    
    close(key)
    {
        this[this.propName(key)] = false;
    }
    
    cityOther()
    {
        this.openedCity = false;
        this.openedCityMenu = true;
    }
    
    hasOpened()
    {
        return (
            this.catalog ||
            this.menu ||
            this.openedCityMenu ||
            this.openedSearch ||
            this.openedCatalogMenu || false );
    }
    
    propName(key)
    {
        return 'opened'+this.capitalizeFirstLetter(key);
    }
    
    capitalizeFirstLetter(string)
    {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}