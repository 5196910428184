import noUiSlider from "nouislider"
import wNumb from "wnumb"

$(document).ready(function() {
    
    $(document).on('click', '.calc-select', function(event) {
        let target = $(event.target);
        let container = target.hasClass('calc-select') ? target : target.closest('.calc-select');
        
        if (target.hasClass('calc-select__title')) {
            container.toggleClass('active');
        } else if (target.hasClass('calc-select__label')) {
            container.removeClass('active');
        }
        
        $('.calc-select').each(function() {
            if (this != container[0]) {
              $(this).removeClass('active');
            }
        });
        
    });
    
    window.addEventListener('click', e => {
        if (!$(event.target).closest('.calc-select').length) {
          $('.calc-select').removeClass('active');
        }
    });
    
    const slidersWrap = document.querySelectorAll('.bd-c-slider__item');

    if (!slidersWrap) return false;

    slidersWrap.forEach(sliderWrap => {

        let slider = sliderWrap.querySelector('.bd-c-slider__slider');
        let input = sliderWrap.querySelector('.bd-c-slider__input');
        
        if (!slider) return;
        
        noUiSlider.create(slider, {
            start: input.value,
            connect: 'lower',
            range: {
                'min': $(input).data('min') || 0,
                'max': $(input).data('max') || 1000
            },
            format: wNumb({
                decimals: $(input).data('decimals') || 0
            }),
        });
        
        slider.noUiSlider.on('update', function(values, handle) {
            input.value = values[handle];
            input.dispatchEvent(new Event('input'));
        });

        input.addEventListener('change', function() {
            slider.noUiSlider.set(this.value);
        });
    });
});
