import debounce from "%functions%/wrappers";

$(document).ready(function () {
    
    const priceAside = document.querySelector('.bd-m-card .bd-price-aside');

    if (!priceAside) return false;

    const priceAsideContent = priceAside.querySelector('.bd-price-aside__container');
    const placeInHeader = document.querySelector('.bd-header-sticky__wrap-bottom--basket');
    const placeInMain = priceAside;
    
    if (!placeInHeader || !placeInMain || !priceAsideContent) return false;
    
    const priceAsideInViewport = () => {
        return priceAside.getBoundingClientRect().top + priceAside.getBoundingClientRect().height > 0;
    };

    const isPriceAsideInPlace = place => place.querySelector('.bd-price-aside__container') !== null;

    const togglePriceAside = debounce(function () {
        if (
            (priceAsideInViewport() && isPriceAsideInPlace(placeInHeader)) ||
            (window.innerWidth > 1450 && isPriceAsideInPlace(placeInHeader))
        ) {
            placeInHeader.classList.remove('bd-show');
            placeInMain.append(priceAsideContent);
            priceAside.style.height = priceAsideContent.style.height;
        }

        if (!priceAsideInViewport() && isPriceAsideInPlace(placeInMain)) {
            priceAside.style.height = priceAside.getBoundingClientRect().height + 'px';
            placeInHeader.append(priceAsideContent);
            placeInHeader.classList.add('bd-show');
        }
    }, 50);

    togglePriceAside();
    window.addEventListener('scroll', togglePriceAside);
    window.addEventListener('resize', togglePriceAside);

});
