$(document).ready(function(){
    const cardImportant = document.querySelector('.bd-card-important');
    const head = document.querySelector('.bd-card-tabs__head--2');
    const body = document.querySelector('.bd-card-tabs__body--2');
    const event = new Event('click');

    if (cardImportant) {

        cardImportant.addEventListener('click', () => {
            head.dispatchEvent(event);
            body.scrollIntoView({
                block: "center"
            });
        })
    }
});
