$(document).ready(function () {

    $(document).on('click', ".bd-count", function (event) {
        let input = this.querySelector('.bd-count__value');
        let step = $(input).data('step') || 1;
        let min = $(input).data('min') || 0;

        if ($(event.target).is('.bd-count__minus')) {
            let value = +input.value;
            if (value-step >= min) {
                input.value = value - step;
                input.dispatchEvent(new Event('input', [input.value]));
            }
        }

        if ($(event.target).is('.bd-count__plus')) {
            let value = +input.value;
            input.value = value + step;
            input.dispatchEvent(new Event('input', [input.value]));
        }

    });
});