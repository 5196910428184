$(document).ready(function () {
    $(".bd-by-slider")
        .slick({
            infinite: false,
            slidesToShow: 1,
            // autoplay: true,
            adaptiveHeight: true,
            autoplaySpeed: 4000,
            prevArrow: $(".bd-by-slider .bd-slick-arrows .slick-prev"),
            nextArrow: $(".bd-by-slider .bd-slick-arrows .slick-next"),
            dots: true,
            appendDots: $(".bd-by-slider .bd-by-slider__dots"),
        })
        .on('afterChange', function (event, slick, currentSlide) {
            $.each(slick.$dots, (i, el) => {
                $(el).find('li').eq(currentSlide).addClass('slick-active').find('button');
            })
        });

});
