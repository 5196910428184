import {initCardSliders} from "%components%/card/card-slider";

$(document).ready(function () {

    const findPrevNext = (target, selector) => {
        let prev = null;
        let next = null;

        let items = $(selector).each(function (index, element) {
            if ($(element).is($(target))) {
                prev = index - 1;
                next = index + 1;
            }
        });

        next = next >= items.length ? null : items[next];
        prev = prev < 0 ? null : items[prev];

        return [prev, next];
    }

    $(document).on('click', '.bd-product__quick-view', function () {
        let next, prev;

        [prev, next] = findPrevNext(this, '.bd-product__quick-view');

        let url = $(this).data('url');
        let container = $('.bd-quick-view');

        container.html('');
        container.bnLoadBig(true);

        $.ajax({
            url: url,
            method: 'get',
            complete: function (jqXHR) {
                let data = jqXHR.responseJSON || {};
                data.content = data.content || jqXHR.responseText;

                try {
                    var html = $.parseHTML(data.content);
                    
                    container.html(html).imagesLoaded().always(function(){
                        container.bnLoadBig(false);
                        initCardSliders(container);
                    });
                    
                    if (data.product) {
                        $(window).trigger('quick-view', [$.parseJSON(data.product)]);
                    }
                    
                } catch (ex) {
                    container.bnLoadBig(false);
                }

                [prev, next].forEach((acter, index) => {
                    let arrow = $('.bd-quick-view__arrow--'+ (index == 0 ? 'prev' : 'next' ) );
                    
                    if (acter) {
                        arrow.removeClass('bd-hide')
                             .unbind('click')
                             .on('click', function () {
                                 container.removeClass('bd-show-prev').removeClass('bd-show-next');
                                 setTimeout(() => {
                                    container.addClass('bd-show-'+(index == 0 ? 'prev' : 'next' ));
                                }, 0);
                                 $(acter).click();
                             });
                    } else {
                        arrow.hide();
                    }
                });
            }
        });
    });

});
