import closingOnOutsideClick from '%components%/close';

$(document).ready(function(){
    const panelSort = document.querySelector('.bd-panel-sort');

    if (!panelSort) return false;

    const list = panelSort.querySelector('.bd-panel-sort__list');
    const selectedText = panelSort.querySelector('.bd-panel-sort__selected span');

    panelSort.addEventListener('click', () => {
        panelSort.classList.toggle('bd-show');
    });

    list.addEventListener('click', e => {
        e.stopPropagation();
        const value = e.target.dataset.sortItem;
        if (value) {
            selectedText.textContent = value;
            panelSort.classList.remove('bd-show');
        }
    });

    closingOnOutsideClick(panelSort);

});
