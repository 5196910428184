export default (wrap, isPortable = false, callback = null) => {
    const wrap_ = document.querySelector(wrap);
    if (!wrap_) return false;

    const heads = wrap_.querySelector("[data-tab-heads]");
    const bodies = wrap_.querySelector("[data-tab-bodies]");
    
    heads.addEventListener("click", e => {
        const currentHead = e.target.closest('[data-tab-head]');
        const numberTab = currentHead.dataset.tabHead;
        
        if (!numberTab) return false;

        const currentBody = bodies.querySelector(`[data-tab-body="${numberTab}"]`);
        const elementNodeListOf = document.querySelectorAll(`[data-tab-head], [data-tab-body]`);

        elementNodeListOf.forEach(el => {
            el.classList.remove("bd-current");
        });

        currentHead.classList.add("bd-current");
        currentBody.classList.add("bd-current");

        if (isPortable /*&& window.innerWidth < 1200*/) {
            const portables = document.querySelectorAll(".bd-filters-portable");
            portables.forEach(portable => {
                const title = portable.querySelector(".bd-filters-portable__title p");
                title.textContent = currentHead.textContent;
                portable.classList.remove("bd-show");
            });
        }
        
        if (callback) {
            callback(numberTab);
        }
    });
}
