import Product from '../models/product.js';
import Widget from './widget.js';

export default class Cart extends Widget
{
    constructor(data)
    {
        super();
        
        this.loadData(data);
        
        this.currency = "RUB";
        this.loading = false;
        
        this.confirmTimer = null;
        
        this.callbacks = {
            "add":    [],
            "change": [],
            "delete": [],
        };
    }
    
    registerCallback(action, callback)
    {
        if (!this.callbacks[action]) {
            this.callbacks[action] = [];
        }
        
        this.callbacks[action].push(callback);
    }
    
    callbackProduct(action, product, deffered = null)
    {
        for (let c of this.callbacks[action] || []) {
            c(this, product, deffered);
        }
    }
    
    callbackProducts(action, products, deffered = null)
    {
        for (let product of products) {
            this.callbackProduct(action, product, deffered);
        }
    }
    
    loadData(data) {
        this.url = data.url;
        this.localization = data.localization;
        this.products = [];
        this.deletedProducts = [];
        this.sale_with = "";
        
        this.loadProducts(data.products);
    }
    
    hasPickupError()
    {
        for(let i in this.products) {
            if (this.products[i].hasError('pickup')) {
                return true;
            }
        }
        
        return false;
    }
    
    setCurrencyManager(manager)
    {
        this.currencyManager = manager;
    }
    
    triggerCurrencyCurrent()
    {
        if (this.currency) {
            this.currency.triggerCurrent();
        }
    }
    
    count()
    {
        return this.products.length;
    }
    
    countSoftDeleted(value = true)
    {
        let c = 0;
        let arr = value ? this.deletedProducts : this.products;
        
        for(let i in arr) {
            c++;
        }
        
        return c;
    }
    
    buildProduct(data) {
        return new Product(data);
    }
    
    buildProducts(data)
    {
        let res = [];

        for (let i in data) {
            res.push(this.buildProduct(data[i]));
        }

        return res;
    }
    
    loadProduct(data)
    {
        this.products.push(this.buildProduct(data));
    }
    
    loadProducts(data)
    {
        this.products.splice(0, this.products.length);
        
        let products = this.buildProducts(data);
        
        for(let i in products) {
            if (this.containsById(products[i].id, true)) {
                this.spliceById(products[i].id, true)
            }
            this.products.push(products[i]);
        }
    }
    
    getIds()
    {
        let ids = [];
        
        for (let i in this.products) {
            ids.push(this.products[i].id);
        }
        
        return ids;
    }
    
    getById(id, softDelete = false)
    {
        let arr = softDelete ? this.deletedProducts : this.products;
        
        for (let i in arr) {
            if (arr[i].id == id) {
                return arr[i];
            }
        }
        
        return null;
    }
    
    containsById(id, softDelete = false)
    {
        let arr = softDelete ? this.deletedProducts : this.products;
        
        for (let i in arr) {
            if (arr[i].id == id) {
                return true;
            }
        }
        
        return false;
    }
    
    spliceById(id, softDelete = false)
    {
        let arr = softDelete ? this.deletedProducts : this.products;
        
        arr.map(function(product, index) {
            if (product.id == id) {
                arr.splice(index, 1)
            }
        });
    }
    
    toggleById(id, changeMark = false)
    {
        if (this.containsById(id)) {
            this.removeById(id, changeMark);
        } else {
            this.addById(id, changeMark);
        }
    }
    
    addById(id, quantity = 1, changeMark = false)
    {
        this.add(new Product({
            id: id,
            quantity: quantity
        }), changeMark);
    }
    
    patchById(id, quantity = 1, changeMark = false)
    {
        return this.patchArray([new Product({
            id: id,
            quantity: quantity
        })], changeMark);
    }
    
    patchByIdArray(products, changeMark = false)
    {
        let res = [];
        
        for(let i in products) {
            res.push(new Product({
                id: i,
                quantity: products[i]
            }));
        }
        
        return this.patchArray(res, changeMark);
    }
    
    deleteByIdArray(products, changeMark = false)
    {
        let res = [];
        
        for(let i in products) {
            res.push(new Product({
                id: products[i]
            }));
        }
        
        return this.deleteArray(res, changeMark);
    }
    
    removeById(id, changeMark = false)
    {
        return this.remove(new Product({
            id: id
        }), changeMark);
    }
    
    add(product, changeMark = false)
    {
        this.update(product, changeMark);
    }
    
    resolveDelete(product, changeMark = false)
    {
        if (this.containsById(product.id, true)) {
            this.spliceById(product.id, true);
        } else {
            this.softDelete(product, changeMark);
        }
    }
    
    softDelete(product, changeMark = false)
    {
        if (!this.containsById(product.id, true)) {
            this.deletedProducts.push(product);
        }
        
        this.remove(product, changeMark);
    }
    
    softUnDelete(product, changeMark = false)
    {
        if (this.containsById(product.id, true)) {
            this.spliceById(product.id, true)
        }
        
        this.add(product, changeMark);
    }
    
    fresh()
    {
        let that = this;
        
        that.loading = true;
        
        return $.ajax({
            url: this.url,
            method: 'get',
            processData: true,
            data: {}
        }).then(function(data){
            
            that.loading = false;
            
            if (data && data.products) {
                that.loadProducts(data.products);
            }
            
            that.prepareConfirm();
            
            return data;
        });
    }
    
    patchArray(products, changeMark = false)
    {
        let that = this;
        
        that.loading = true;
        
        let deffered = $.ajax({
            url: this.url,
            method: 'patch',
            processData: true,
            data: {
                "change_mark": changeMark,
                "products": products
            }
        }).then(function(data){
            
            that.loading = false;
            
            if (data && data.products) {
                that.loadProducts(data.products);
            }
            
            if (data.sale_with) {
                that.sale_with = data.sale_with;
            } else {
                that.sale_with = "";
            }
            
            that.prepareConfirm();
            
            return data;
        });
        
        that.callbackProducts('add', products, deffered);
        
        return deffered;
    }
    
    clear(changeMark = false)
    {
        return this.deleteArray(this.products, changeMark);
    }
    
    clearSoft(changeMark = false)
    {
        for (let product of this.products) {
            this.deletedProducts.push(product);
        }
        
        return this.clear(changeMark);
    }
    
    deleteArray(products, changeMark = false)
    {
        let that = this;
        
        that.loading = true;
        
        let deffered = $.ajax({
            url: this.url,
            method: 'delete',
            processData: true,
            data: {
                "change_mark": changeMark,
                "products": products
            }
        }).then(function(data){
            
            that.loading = false;
            
            if (data && data.products) {
                that.loadProducts(data.products);
            }
            
            if (data.sale_with) {
                that.sale_with = data.sale_with;
            } else {
                that.sale_with = "";
            }
            
            that.prepareConfirm();
            
            return data;
        });
        
        that.callbackProducts('delete', products, deffered);
        
        return deffered;
    }
    
    confirm()
    {
        return $.ajax({
            url: this.url,
            method: 'get',
            processData: true,
            data: {
                "confirm": 'true'
            }
        })
    }
    
    update(product, changeMark = false)
    {
        let that = this;
        
        that.loading = true;
        
        let deffered = $.ajax({
            url: this.url,
            method: 'put',
            processData: true,
            data: {
                "change_mark": changeMark,
                "product": product
            }
        }).then(function(data){
            
            that.loading = false;
            
            if (data && data.products) {
                that.loadProducts(data.products);
            }
            
            if (data.sale_with) {
                that.sale_with = data.sale_with;
            } else {
                that.sale_with = "";
            }
            
            that.prepareConfirm();
            
            return data;
        });
        
        that.callbackProduct('change', product, deffered);
        
        return deffered;
    }
    
    remove(product, changeMark = false)
    {   
        let that = this;
        
        that.loading = true;
        
        let deffered = $.ajax({
            url: this.url,
            method: 'delete',
            processData: true,
            data: {
                "change_mark": changeMark,
                "product": product
            }
        }).then(function(data){
            
            that.loading = false;
            
            if (data && data.products) {
                that.loadProducts(data.products);
            }
            
            that.prepareConfirm();
            
            that.sale_with = "";
        });
        
        that.callbackProduct('delete', product, deffered);
        
        return deffered;
    }
    
    getTotal(products, method)
    {
        var res = 0;
        for (var i in products) {
            if (!products[i].softDelete) {
                
                let product = products[i];
                
                if (method == 'totalPrice') {
                    res += this.getProductPrice(product);
                } else {
                    res += product[method]();
                }
            }
        }
        return res;
    }
    
    getProductPrice(product, quantity = null)
    {
        
        quantity = quantity || product.quantity;
        
        if (product.quantity >= product.price_quantity) {
            return this.currencyManager.roundByIso(this.currency, product.totalPrice(quantity));
        } else {
            return quantity*this.currencyManager.roundByIso(this.currency, product.totalPrice(1));
        }
    }
    
    totalPrice()
    {
        return this.getTotal(this.products, 'totalPrice');
    }
    
    totalWeight()
    {
        return this.getTotal(this.products, 'totalWeight');
    }
    
    totalSize()
    {
        return this.getTotal(this.products, 'totalSize');
    }
    
    prepareConfirm()
    {
        let that = this;
        
        clearTimeout(that.confirmTimer);
        
        if (that.opened) {
            that.confirmTimer = setTimeout(function() {
                if (that.opened) {
                    that.confirm();
                }
            }, 3000);
        }
    }
}
