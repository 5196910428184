import "core-js/stable";
import "regenerator-runtime/runtime";

import $ from 'jquery';

global.jQuery = $;
global.$ = $;

import "slick-carousel";
import "jquery-number";
import "bootstrap";
require('imagesloaded');
import "@fancyapps/fancybox";
require("suggestions-jquery");

import './bootstrap.js';
import './bootstrapVue.js';
import './policy.js';
