export default class Product {
    constructor(data) {
        this.fill(data);
    }
    
    fill(data)
    {
        let that = this;
    
        that.id = data.id;
        that.url = data.url;
        that.name = data.name;
        that.full_name = data.full_name;
        that.image = data.image;
        that.package_type = data.package_type;
        
        that.price  = parseFloat(data.price);
        that.price_title = data.price_title;
        that.price_quantity = parseInt(data.price_quantity) || 1;
        
        that.type_name = data.type_name;
        
        that.currency = data.currency;
        
        that.weight = parseFloat(data.weight);
        that.size   = parseFloat(data.size);
        that.amount = parseInt(data.amount);
        
        that.quantity = parseInt(data.quantity);
        
        that.softDelete = false;
        
        that.product_list = {};
        
        for(let i in data.product_list) {
            that.product_list[i] = data.product_list[i];
        }
        
        that.props = {};
        
        for(let i in data.props) {
            that.props[i] = data.props[i];
        }
        
        that.errors = [];
        
        for(let i in data.errors) {
            that.errors.push({
                type: i,
                text: data.errors[i]
            });
        }
    }
    
    getBasePrice() {
        return this.price/this.price_quantity;
    }
    
    totalPrice(quantity = null) {
        quantity = quantity || this.quantity;
        
        return quantity*this.getBasePrice();
    }
    
    totalWeight(quantity = null) {
        quantity = quantity || this.quantity;
        return quantity*this.weight;
    }
    
    totalSize(quantity = null) {
        quantity = quantity || this.quantity;
        return quantity*this.size;
    }
    
    hasError(type)
    {
        for(let i in this.errors) {
            if (this.errors[i].type == type) {
                return true;
            }
        }
        
        return false;
    }
}