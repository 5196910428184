import * as bootstrap from 'bootstrap';

$(document).ready(function(){
    
    if ($('.notification-modal').length) {
        
        let myModal = new bootstrap.Modal($('.notification-modal')[0], {});
        myModal.show();
    }
    
})