export default {
    bind: function(element, binding, vnode) {

    },
    inserted: function (element, binding, vnode) {
        var option = binding.value.type || null;
        var change = binding.value.change || null;
        var disabled = binding.value.disabled || false;
        var region = binding.value.region || null;
        var city  = binding.value.city || null;
        var type  = null;
        var bound = null;
        
        if (option == 'ADDRESS_STREET') {
            type  = 'ADDRESS';
            bound = 'street';
        } else {
            type = option;
        }
        
        var $o = {
            token: $('meta[name="dadata-token"]').attr('content'),
            type: type,
            count: 5,
            onSelect: function (suggestion) {
                element.dispatchEvent(new Event("input", {}));
                if (change) {
                    change(suggestion);
                }
            }
        }
        
        if (bound) {
            $o.bounds = bound;
            
            $o.constraints = [{
                locations: [
                    {region: region, city: city},
                    {region: region, settlement: city},
                ] ,
            }];
        }
        
        if (option) {

          $(element).suggestions($o);
          $(element).attr("autocomplete", "off");
          $(element).suggestions().initializer.resolve();
          $(element).suggestions().enable();

          if (option == 'ADDRESS' && disabled) {
              $(element).suggestions().disable();
//              if (typeof ymaps != typeof undefined) {
//                $(element).data('SuggestView', new ymaps.SuggestView(element));
//              }
          }
        }
    },
    update: function(element, binding, vnode, oldVnode) {

        var option = binding.value.type || null;
        var disabled = binding.value.disabled || false;
        var region = binding.value.region || null;
        var city  = binding.value.city || null;
        var type  = null;
        var bound = null;
        
        var fixCityName = function(name) {
          name = new String(name);
          
          name = name.replaceAll('Ё', "Е");
          name = name.replaceAll('ё', "е");
          name = name.replaceAll('"', "");
          name = name.replaceAll("'", "");
          
          return name;
        }
        
        if (option == 'ADDRESS_STREET') {
            type  = 'ADDRESS';
            bound = 'street';
        } else {
            type = option;
        }
        
        if (!$(element).suggestions()) {
            return;
        }
        
        if (bound) {
            $(element).suggestions().setOptions({
                bounds: bound,
                constraints: [{
                    locations: [
                        {region: region, city: city},
                        {region: region, settlement: city},
                    ],
                }],
                onSuggestionsFetch(suggestions) {
                  
//                    console.info(arguments);
                    let res = [];
                    for (let info of suggestions) {
                      
                        var $settlementFinded = false;
                        var $areaFinded = false;
                        var $cityFinded = false;
                        var $regionFinded  = false;

                        var $settlementPresist = false;
                        var $areaPresist = false;
                        var $cityPresist = false;
                        var $regionPresist = false;

                        if (info.data.settlement && (info.data.settlement_type_full != 'территория' && info.data.settlement_type_full != 'микрорайон') ) {
                            $settlementPresist = true;
                            $settlementFinded  = fixCityName(info.data.settlement) == fixCityName(city);
                        }

                        if (info.data.area && !info.data.settlement ) {
                            $areaPresist = true;
                            $areaFinded = fixCityName(info.data.area) == fixCityName(city);
                        }

                        if (info.data.city) {
                            $cityPresist = true;
                            $cityFinded = fixCityName(info.data.city) == fixCityName(city);
                        }

                        if (info.data.region) {
                            $regionPresist = true;
                            $regionFinded = fixCityName(info.data.region) == fixCityName(city);
                        }

                        var $passes = true;

                        if ($settlementPresist) {
                            $passes &= $settlementFinded;
                        } else if ($areaPresist && $areaFinded) {
                            $passes &= true;
                        } else if ($cityPresist) {
                            $passes &= $cityFinded;
                        } else if ($regionPresist) {
                            $passes &= $regionFinded;
                        } else {
                            $passes &= false;
                        }
                      
                        if ($passes) {
                            res.push(info);
                        }
                    }
                    return res;
                }
            });
        } else {
            $(element).suggestions().setOptions({
                bounds: null,
                constraints: null
            });
        }
        
        if (option == 'ADDRESS' || option == 'ADDRESS_STREET') {
            
            if (disabled) {
              $(element).suggestions().disable();

//              $(element).parent().filter('.suggestions-wrapper').addClass('hide')

//              if (typeof ymaps != typeof undefined) {
//                if (!$(element).data('SuggestView')) {
//                  $(element).data('SuggestView', new ymaps.SuggestView(element));
//                }
//              }

            } else {
              $(element).suggestions().enable();
//              $(element).parent().filter('.suggestions-wrapper').removeClass('hide')

//              if ($(element).data('SuggestView')) {
//                $(element).data('SuggestView').destroy();
//                $(element).data('SuggestView', null);
//              }
            }
        }
    },
    componentUpdated: function(element, binding, vnode, oldVnode) {

    },
    unbind: function(element, binding, vnode) {
        
    }
}