import LocationRaw from '../models/location.js';

export default class Location {
    
    constructor(data)
    {
        this.url = data.url;
        
        if (data.location) {
            this.location = new LocationRaw(data.location);
        }
    }
    
    search(name)
    {
        let that = this;
        
        return $.ajax({
            url: that.url,
            method: 'post',
            enctype: 'multipart/form-data',
            data: {search: name}
        }).then(function(data) {
            let res = [];
            
            if (data && data.citys) {
                for (let i in data.citys) {
                    res.push(new LocationRaw(data.citys[i]));
                }
            }
            
            return res;
        });
    }
    
    searchFull(name)
    {
        let that = this;
        
        return $.ajax({
            url: that.url,
            method: 'post',
            enctype: 'multipart/form-data',
            data: {search: name, full: true}
        }).then(function(data) {
            let res = [];
            
            if (data && data.citys) {
                for (let i in data.citys) {
                    res.push(new LocationRaw(data.citys[i]));
                }
            }
            
            return res;
        });
    }
    
    select(location)
    {
        let that = this;
        that.location = location;
        
        $(window).trigger('location.selected', [location]);
        
        return $.ajax({
            url: that.url,
            method: 'put',
            enctype: 'multipart/form-data',
            data: {location: location},
            complete: function(jqXHR) {
                let data = jqXHR.responseJSON;
                
            }

        });
    }
    
    confirm()
    {
        this.select(this.location);
    }
}