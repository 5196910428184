import Widget from './widget.js';

export default class Filter extends Widget
{
    constructor(data)
    {
        super();
        
        this.total = 0;
        this.title = "";
        
        this.attributes = [];
        this.showTooltip = false;
    }
    
    tooltip()
    {
        return (this.showTooltip);
    }
    
    changeTooltip(status)
    {
        this.showTooltip = status ? true : false;
    }
    
    updateStatus(form)
    {
        let that = this;
        let formData = new FormData(form);
        
        that.total = 0;
        that.title = "";
        that.showTooltip = false;
        
        if (that.formDataSize(formData)) {
            $.ajax({
                url: form.action,
                method: 'post',
                processData: false,
                contentType: false,
                enctype: 'multipart/form-data',
                data: formData,
                complete: function(jqXHR) {
                    that.proccessData(jqXHR.responseJSON || {});
                }

            });
        } else {
            that.showTooltip = false;
        }
    }
    
    proccessData(data)
    {
      let that = this;
      
      that.total = (data && data.total) ? data.total : 0;
      that.title = (data && data.title) ? data.title : "";

      that.showTooltip = true;
    }
    
    formDataSize(formData)
    {
        let counter = 0;
        
        formData.forEach(function(value, key) {
            counter++;
        });
        
        return counter;
    }
}