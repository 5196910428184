var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "bd-search-input-result__item" }, [
    _c("a", {
      staticClass: "bd-search-input-result__link",
      attrs: { href: _vm.product.url },
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "bd-search-input-result__img",
      attrs: { src: _vm.product.image },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "bd-search-input-result__content" }, [
      _c("div", {
        staticClass: "bd-search-input-result__title",
        domProps: { innerHTML: _vm._s(_vm.product.full_name) },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "bd-search-input-result__desc" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }